import React, {useEffect, useState} from 'react';
import {ToeflIbtContext} from "./ToeflIbtContext";
import {Box, Tab, TabList, Tabs} from '@chakra-ui/react';
import {ViewResultPanel} from "../ViewResultPanel";
import ToeflWaitingPage from "./ToeflWaitingPage";

function ToeflSpeakingAnswer() {
    const {questionContent, answerContent} = React.useContext(ToeflIbtContext);
    const [tabIndex, setTabIndex] = useState(0);
    const [gid, setGid] = useState(null);
    const [isAnswered, setIsAnswered] = useState(false);


    useEffect(() => {
        const gid = answerContent?.['speaking']?.[tabIndex + 1]?.['gid'];
        const answer = answerContent?.['speaking']?.[tabIndex + 1]?.['answer'];
        const status = answerContent?.['speaking']?.[tabIndex + 1]?.['status'];
        console.log("gid", gid)
        console.log("answer", answer)
        console.log("status", status)
        setIsAnswered(!!answer);
        if (gid && gid.startsWith('G_') && (status === "evaluated")) {
            setGid(gid);
        } else {
            setGid(null)
        }
    }, [answerContent, tabIndex]);

    return (
        <Box margin="auto" padding="1" bg={'gray.100'} height={"92vh"} width={'100%'}>
            <Tabs variant='enclosed' bg={'white'} borderRadius={'md'}>
                <TabList>
                    {questionContent?.['speaking']?.tasks.map((task, index) => (
                        <Tab key={index} onClick={() => {
                            setTabIndex(index)
                        }}>
                            Task {index + 1}
                        </Tab>
                    ))}
                </TabList>
            </Tabs>
            {(gid) ? <ViewResultPanel gid={gid}></ViewResultPanel>
                : <ToeflWaitingPage isAnswered={isAnswered}/>}
        </Box>
    );
}

export default ToeflSpeakingAnswer;
