import {useTranslation} from "react-i18next";
import {Badge, Box, Button, Center, Flex, Stack, StackDivider, Text} from "@chakra-ui/react";
import {useHistory} from "react-router-dom";
import QuestionCardNewUserPopover from "./QuestionCardNewUserPopover";
import React from "react";
import mixpanel from "mixpanel-browser";
import {PracticeTypeEnum} from "./toeflIbt/ToeflIbtContext";

export function QuestionCard(props) {
    var {
        genType,
        summary,
        tags,
        tpoNumber,
        orderIndex,
        questionSet,
        title,
        qid,
        completed,
        isFree,
        balanceValue,
        onAlertOpen,
        isFullSet,
        section,
        ...other
    } = props;
    // let section = getSection(genType);
    let history = useHistory();
    const {t, i18n} = useTranslation();
    const generationTasks = ["speaking_task1", "speaking_task2", "speaking_task3", "speaking_task4", "writing_task1", "writing_task3"]
    const enableGeneration = generationTasks.includes(genType);
    // const disabled = (balanceValue <= 0 && !isFree) || (balanceValue <= 900 && section === PracticeTypeEnum.FULL_MOCK_TEST); // 积分小于0且不是免费题目、全科模拟且积分小于900
    const disabled = (balanceValue <= 0 && !isFree); // 积分小于0且不是免费题目、全科模拟且积分小于900

    // function getSection(genType) {
    //     if (genType.includes('listening')) {
    //         return 'listening';
    //     } else if (genType.includes('reading')) {
    //         return 'reading';
    //     } else if (genType.includes('writing')) {
    //         return 'writing';
    //     } else if (genType.includes('speaking')) {
    //         return 'speaking';
    //     } else {
    //         return 'unknown';
    //     }
    // }

    const PracticeButton = ({buttonText, buttonUrl}) => {
        const handleButtonClick = (e) => {
            let practice_type = buttonText
            if (practice_type === 'ibt_practice') {
                if (buttonUrl.includes("all_")) {
                    practice_type = PracticeTypeEnum.FULL_MOCK_TEST
                } else {
                    practice_type = "mock_test"
                }
            } else if (buttonText === "practice_mode") {
                practice_type = "practice"
            }
            mixpanel.track('Practice Button Click', {
                name: "Practice",
                type: practice_type,
                section: section,
                isFullSet: isFullSet,
                isFree: isFree
            });
            if (disabled) {
                onAlertOpen();
            } else {
                history.push(buttonUrl);
            }
        };

        return (
            <Button
                value={tpoNumber}
                size='sm'
                borderRadius='full'
                p="15px"
                width={{sm: "full"}}
                sx={{
                    color: 'white',
                    bg: disabled ? 'gray.500' : 'blue.500',
                    borderColor: disabled ? 'gray.700' : 'blue.500',
                    '&:hover': {
                        bg: disabled ? 'gray.600' : 'blue.600',
                        borderColor: disabled ? 'gray.600' : 'blue.600'
                    }
                }}
                onClick={handleButtonClick}
            >
                {/*{buttonText}*/}
                {t(buttonText)}
            </Button>
        );
    };


    return (
        <Box
            bg="gray.100"
            borderRadius='20px'
            px={{lg: "20px", xl: "20px", md: "20px", sm: "6px"}}
            pt="20px"
            pb="30px"
            shadow="md"
            height="90%"
            width={{lg: "90%", md: "100%", sm: "100%"}}
        >
            <Flex direction={"row"} justify="space-between">
                <Box display="flex" alignItems="center">
                    <Text fontSize="md" fontWeight='bold'>{title}</Text>
                    {isFree && balanceValue <= 0 &&
                        <Text color="#2E75C5" fontSize="md" fontWeight='bold'>（{t("free_trial")}）</Text>}
                </Box>
                <Center display={completed ? "block" : "none"}>
                    <Badge
                        variant='solid' colorScheme='yellow' py="4px" px="8px" borderRadius="full"
                    >
                        {t("completed")}
                    </Badge>
                </Center>
            </Flex>
            <Stack divider={<StackDivider/>} spacing="4">
                <Box>
                    <Text pt="2" fontSize="sm" isTruncated>
                        {summary && summary.replace(/<br\s*\/?>/gi, ' ')}
                        {/* <div dangerouslySetInnerHTML={{ __html: summary }} /> */}
                    </Text>
                    {tags &&
                        <Stack direction="row" wrap="wrap" spacing={2}>
                            {tags.map(tag => (
                                <Box key={tag.id} p={1}>
                                    <Badge
                                        colorScheme="blue"
                                        fontSize="0.85em"
                                        borderRadius="lg"
                                        px={3}
                                        py={1}
                                        textTransform="none"
                                        boxShadow="sm"
                                    >
                                        <Text isTruncated maxWidth="150px">
                                            {tag[i18n.language]}
                                        </Text>
                                    </Badge>
                                </Box>
                            ))}
                        </Stack>}
                    <Box mt="20px">
                        {!['reading', 'listening', PracticeTypeEnum.FULL_MOCK_TEST].includes(section) ?
                            (<Flex gap="10px" direction={{lg: "column", xl: "row", md: "column", sm: "column"}}>
                                <Flex flexDirection="column">
                                    <PracticeButton
                                        buttonText={"practice_mode"}
                                        buttonUrl={genType.startsWith('i') ? `/ielts/${section}/practice?qid=${qid}` : `/toefl/${section}/practice?qid=${qid}`}
                                    ></PracticeButton>
                                    <Center>
                                        {
                                            tpoNumber === 1 && genType.includes('task1') &&
                                            <QuestionCardNewUserPopover></QuestionCardNewUserPopover>
                                        }
                                    </Center>
                                </Flex>
                                <Box display={enableGeneration ? 'block' : 'none'}>
                                    <PracticeButton
                                        buttonText={"corpus_accumulation"}
                                        buttonUrl={`/toefl/${section}/corpus_generation?qid=${qid}`}
                                    ></PracticeButton>
                                </Box>
                                <Box display={genType.startsWith('i') ? "none" : "block"}>
                                    <PracticeButton
                                        buttonText={"ibt_practice"}
                                        buttonUrl={`/mock_test?section=${section}&task_name=${genType.split("_")[1]}&qid=${qid}`}
                                        // buttonUrl={`/toefl_ibt/${questionSet}_${tpoNumber === -1 ? orderIndex : tpoNumber}/${section}/${qid}`}
                                    ></PracticeButton>
                                </Box>
                            </Flex>) :
                            isFullSet ? (
                                section === PracticeTypeEnum.FULL_MOCK_TEST ?
                                    (<PracticeButton
                                        buttonText={"ibt_practice"}
                                        buttonUrl={`/toefl_ibt/${questionSet}_${tpoNumber === -1 ? orderIndex : tpoNumber}/all_reading/all`}
                                    ></PracticeButton>)
                                    : (<PracticeButton
                                        buttonText={"ibt_practice"}
                                        buttonUrl={`/toefl_ibt/${questionSet}_${tpoNumber === -1 ? orderIndex : tpoNumber}/${section}/all`}
                                    ></PracticeButton>)
                            ) : (
                                <PracticeButton
                                    buttonText={"practice_mode"}
                                    buttonUrl={`/toefl_ibt/${questionSet}_${tpoNumber === -1 ? orderIndex : tpoNumber}/${section}/${qid}`}
                                ></PracticeButton>
                            )
                        }
                    </Box>
                </Box>
            </Stack>
        </Box>
    );
}