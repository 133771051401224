import {InfoIcon} from '@chakra-ui/icons';
import {
    Box,
    Button,
    CircularProgress,
    Divider,
    Flex,
    Heading,
    HStack,
    Link,
    Popover,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverTrigger,
    Table,
    Tbody,
    Td,
    Text,
    Textarea,
    Th,
    Thead,
    Tooltip,
    Tr,
    useToast,
    VStack
} from '@chakra-ui/react';
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {AiOutlineBarChart} from "react-icons/ai";
import {BsTextIndentRight} from "react-icons/bs";
import {FaHeadphonesAlt, FaReadme, FaStar} from "react-icons/fa";
import {FaAngleDown, FaAngleRight, FaAngleUp, FaCheck, FaLink} from "react-icons/fa6";
import {FiFileText, FiMic, FiPrinter} from "react-icons/fi";
import {IoCalendarOutline, IoRocketOutline, IoShareSocialOutline} from "react-icons/io5";
import {LuRepeat} from "react-icons/lu";
import {ToeflIbtContext} from "./ToeflIbtContext";
import {TbTriangleInvertedFilled} from "react-icons/tb";
import {useHistory} from "react-router-dom";
import axios from "axios";
import authHeader from "../../services/auth-header";
import {useSubmitFeedbackMutation} from "../../services/cmApi";

const API_URL = process.env.REACT_APP_CM_BACKEND_URL ? process.env.REACT_APP_CM_BACKEND_URL : window.location.origin;


export function isEqual(answer1, answer2) {
    const preprocess = (value) => {
        if (Array.isArray(value)) {
            value = value.join("");
        }
        let vStrs = '';
        value.split("#").map((vStr) => {
            vStrs += vStr.split("").sort().join("");
            vStrs += '#'
        })
        return vStrs;
    };
    const processedAnswer1 = preprocess(answer1 || '');
    const processedAnswer2 = preprocess(answer2 || '');
    return processedAnswer1 === processedAnswer2;
}

const ScoreArc = ({score, maxScore}) => {
    const {t, i18n} = useTranslation();
    const normalizedScore = (score / (maxScore * 2)) * 100;


    return (
        <VStack>
            <Text fontWeight={'bold'} fontSize={'xl'} mb={-2}>{t('total_score')}</Text>
            <Box position="relative" align="center">
                <Box w="198px" h="99px" overflow="hidden">
                    <CircularProgress
                        value={normalizedScore}
                        size="198px"
                        thickness="8px"
                        color='#057A55'
                        trackColor="#E2E8F0"
                        capIsRound
                        style={{transform: "rotate(-90deg)"}}
                    />
                </Box>
                <Box position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)">
                    <VStack spacing={0} align="center">
                        <Text fontSize={'50px'} height={'60px'} mt={10}>{score}</Text>
                        <Text fontSize="lg">Out of {maxScore}</Text>
                    </VStack>
                </Box>
            </Box>
        </VStack>
    );
};

function ToeflScoreReport({sections, bestScoreDic}) {
    const {
        questionContent,
        setQuestionContent,
        answerContent,
        setAnswerContent,
        mockJson,
        setMockJson,
        mockId
    } = React.useContext(ToeflIbtContext);
    const [scores, setScores] = useState({});
    const [sectionsMockJson, setSectionsMockJson] = useState({});
    const {t, i18n} = useTranslation();
    const [isScoresOpen, setIsScoresOpen] = useState(true);
    const [isScoreRangesOpen, setIsScoreRangesOpen] = useState(true);
    const [starsNum, setStarsNum] = useState(0)
    const history = useHistory();
    const toast = useToast();
    const [submitFeedback, {isLoading, isError, error}] = useSubmitFeedbackMutation();
    const [text, setText] = useState("");
    const [email, setEmail] = useState("");

    const [evaluationDate, setEvaluationDate] = useState(Math.floor(Date.now() / 1000))


    useEffect(() => {
        const getMockJson = async () => {
            const data = {};
            // 单科内合并
            ['reading', 'listening'].forEach(section => {
                data[section] = {};
                Object.entries(mockJson?.[section] || {}).forEach(([qid, categoryDic]) => {
                    Object.entries(categoryDic).forEach(([category, valueDic]) => {
                        if (!data[section][category]) {
                            data[section][category] = {
                                "Total": 0,
                                'Correct': 0,
                                'Incorrect': 0,
                                'Unattempted': 0
                            };
                        }
                        Object.entries(valueDic).forEach(([title, value]) => {
                            if (typeof value === 'number') {
                                data[section][category][title] += value;
                            }
                        });
                    });
                });
            });
            for (const section of ['speaking', 'writing']) {
                const sectionQuestions = questionContent?.[section]?.tasks;
                data[section] = {};
                let evaluationAt;
                for (const [index, task] of sectionQuestions?.entries() || []) {
                    const answer = answerContent?.[section]?.[index + 1]?.['gid'];
                    const score = answerContent?.[section]?.[index + 1]?.['score'];
                    evaluationAt = evaluationAt || answerContent?.[section]?.[index + 1]?.['evaluationAt'];
                    data[section][t(task.gen_type)] = {
                        "Total": 1,
                        "Score": score || 0,
                        "Notes": task?.short_summary?.['en'] || ''
                    };
                    if (answer && (!score || score === 0)) { // 如果有答题记录，则尝试获取评分
                        try {
                            let gradingResult = await getGrading(answer);
                            if (gradingResult?.status === 200 && gradingResult?.data.data) {
                                const estimatedScore = gradingResult.data.data['estimated_score'];
                                evaluationAt = evaluationAt || gradingResult.data.data['created_at'];
                                if (estimatedScore) {
                                    data[section][t(task.gen_type)]['Score'] = estimatedScore
                                }
                            }
                        } catch (error) {
                            console.error('Error fetching grading result:', error);
                        }
                    }
                }
                setEvaluationDate(evaluationAt || Math.floor(Date.now() / 1000))
            }
            setSectionsMockJson(data);
        }
        getMockJson();
    }, [mockJson, questionContent, answerContent]);


    useEffect(() => {
        // 获取当前分数
        let totalScore = 0;
        let scoresDic = {};
        for (const section of sections) {
            try {
                const score = Number(getScore({section: section}));
                scoresDic[section] = score || 0;
                totalScore += score || 0;
            } catch (error) {
                console.error(`Error fetching score for section ${section}:`, error);
                scoresDic[section] = 0;
            }
        }
        scoresDic['total'] = totalScore;
        setScores(scoresDic);
        // 获取历史最高分

    }, [sectionsMockJson]);


    function getScore({section}) {
        if (['reading', 'listening'].includes(section)) {
            let correctCount = 0;
            const smockJson = sectionsMockJson?.[section] || {};
            if (smockJson) {
                Object.entries(smockJson).map(([category, valueDic]) => {
                    correctCount += valueDic['Correct'] || 0;
                });
            }
            if (section === 'reading') {
                return TOEFL_READING_SCORE[correctCount];
            } else if (section === 'listening') {
                return TOEFL_LISTENING_SCORE[correctCount];
            }
        } else { // 口语写作计算平均分
            let totalScores = [];
            const smockJson = sectionsMockJson?.[section] || {};
            if (smockJson) {
                Object.entries(smockJson).map(([category, valueDic]) => {
                    totalScores.push(valueDic['Score'] || 0);
                });
            }
            return totalScores.length > 0 ? Math.round(totalScores.reduce((a, b) => a + b, 0) / totalScores.length) : 0;
        }
    }


    async function getGrading(gid) {
        try {
            const response = await axios.get(`${API_URL}/api/get_grading?gid=${gid}`, {headers: authHeader()});
            if (response.status === 200) {
                console.log(`${mockId}: Get Grading successfully`);
                return response;
            }
        } catch (error) {
            console.log(`${mockId}: Error Get Grading`, error);
            toast({
                title: "Failed to get answer",
                description: error.message,
                status: "error",
                duration: 2000,
                isClosable: true,
            });
            return null; // 抛出错误以便调用者可以捕获
        }
    }


    const ScoreSection = ({section}) => {
        let icon;
        if (section === 'listening') {
            icon = <FaHeadphonesAlt color='green'/>;
        } else if (section === 'reading') {
            icon = <FaReadme color='green'/>;
        } else if (section === 'speaking') {
            icon = <FiMic color='green'/>;
        } else if (section === 'writing') {
            icon = <FiFileText color='green'/>;
        }

        return (
            <VStack>
                <Flex direction={'row'} alignItems={'center'} mt={2}>
                    {icon}
                    <Text fontWeight={'bold'} ml={1}>{t(section)}</Text>
                </Flex>
                <Box
                    borderRadius="md"
                    bgColor={'#DEF7EC'}
                    width={'156px'}
                    height={'81px'}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                >
                    <Text fontWeight={'bold'} fontSize={'32px'} height={'38px'}
                          transform="translateY(-4px)">{scores?.[section]}</Text>
                    <Text mb={1} fontSize={'md'}>out of 30</Text>
                </Box>
            </VStack>
        );
    };

    const BestScoreSection = ({section}) => {

        return (
            <VStack>
                <Flex direction={'row'} alignItems={'center'} mt={2}>
                    <Text fontWeight={'bold'} ml={1}>{t(section)}(0-30)</Text>
                </Flex>
                <Box
                    borderRadius="md"
                    bgColor={'#FEECDC'}
                    width={'156px'}
                    height={'108px'}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                >
                    <Text fontWeight={'bold'} fontSize={'32px'} height={'38px'}
                          transform="translateY(-4px)">{bestScoreDic?.[section]?.['max_score'] || 0}</Text>
                    <Text fontSize={'md'}>Test Date</Text>
                    <Text mb={1}
                          fontSize={'md'}>{formatDate(bestScoreDic?.[section]?.['created_at'] || Math.floor(Date.now() / 1000))}</Text>
                </Box>
            </VStack>
        );
    };

    function formatDate(dateString) {
        const timestampInMilliseconds = Number(dateString) * 1000; // 将秒转换为毫秒
        const date = new Date(timestampInMilliseconds);
        return date.toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric'
        });
    }

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const ScoreDetailTable = ({section, score, maxScore, details}) => {
        const {t} = useTranslation();
        const levelDic = {
            'reading': {
                'Below Low-Intermediate': [0, 1, 2, 3],
                'Low-Intermediate': [4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17],
                'High-Intermediate': [18, 19, 20, 21, 22, 23],
                'Advanced': [24, 25, 26, 27, 28, 29, 30]
            },
            'listening': {
                'Below Low-Intermediate': [0, 1, 2, 3, 4, 5, 6, 7, 8],
                'Low-Intermediate': [9, 10, 11, 12, 13, 14, 15, 16],
                'High-Intermediate': [17, 18, 19, 20, 21],
                'Advanced': [22, 23, 24, 25, 26, 27, 28, 29, 30]
            },
            'speaking': {
                'Below Basic': [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
                'Basic': [10, 11, 12, 13, 14, 15],
                'Low-Intermediate': [16, 17, 18, 19],
                'High-Intermediate': [20, 21, 22, 23, 24],
                'Advanced': [25, 26, 27, 28, 29, 30]
            },
            'writing': {
                'Below Basic': [0, 1, 2, 3, 4, 5, 6],
                'Basic': [7, 8, 9, 10, 11, 12],
                'Low-Intermediate': [13, 14, 15, 16],
                'High-Intermediate': [17, 18, 19, 20, 21, 22, 23],
                'Advanced': [24, 25, 26, 27, 28, 29, 30],
            },
            'color': {
                'Below Basic': '#F9F9F9',
                'Basic': '#E4E5EA',
                'Below Low-Intermediate': '#F9F9F9',
                'Low-Intermediate': '#D1D9E6',
                'High-Intermediate': '#B1BCD0',
                'Advanced': '#8494AB'
            }
        }

        const scoreBoxs = () => {
            const left = score * 10 - 8
            const levelEntry = Object.entries(levelDic[section]).find(([level, ranges]) => ranges.includes(score));
            const level = levelEntry ? levelEntry[0] : null;
            let levelInfoElements = [];
            Object.entries(levelDic[section]).forEach(([level, ranges]) => {
                levelInfoElements.push(
                    <div
                        key={level}
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            margin: '8px 0', // 添加垂直空白
                            fontSize: '12px' // 减小字体大小
                        }}
                    >
                        <span>{level}</span>
                        <span style={{flex: 1}}></span> {/* 使用 flex: 1 来增加中间的空白 */}
                        <span>{ranges[0]}-{ranges[ranges.length - 1]}</span>
                    </div>
                );
            });
            levelInfoElements.push(
                <div
                    key={level}
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        margin: '5px 0', // 添加垂直空白
                    }}
                >
                    <span style={{fontWeight: 'bold'}}>{capitalizeFirstLetter(section)}</span>
                    <span style={{flex: 1}}></span> {/* 使用 flex: 1 来增加中间的空白 */}
                    <span style={{fontWeight: 'bold'}}>0-30</span>
                </div>);
            levelInfoElements.reverse();

            return (
                <Flex alignItems="center" mb={6}>
                    <Text fontSize="lg" mr={4}>
                        {score}/{maxScore}
                    </Text>
                    <Box position="relative">
                        <Box width={'300px'} height={'30px'} borderRadius={'md'} borderWidth={'1px'}
                             borderColor={'black'}
                             overflow="hidden" display="flex">
                            {Object.entries(levelDic[section]).map(([level, ranges]) => {
                                const width = Array.isArray(ranges) ? ranges.length * 10 : 0; // 确保 ranges 是数组
                                const color = levelDic['color'][level];
                                return (
                                    <Box key={level} width={`${width}px`} height={'30px'}
                                         borderRight={level === 'Advanced' ? 'none' : '1px'}
                                         borderLeftColor={'black'} bgColor={color}/>
                                );
                            })}
                        </Box>
                        <TbTriangleInvertedFilled color={'red'} style={{
                            position: 'absolute',
                            top: "-10px",
                            left: `${left}px`,
                        }}></TbTriangleInvertedFilled>
                    </Box>
                    <Flex alignItems="center" ml={4}>
                        <Text fontWeight="bold" mr={2}>
                            {level}
                        </Text>
                        <Tooltip label={levelInfoElements} placement="bottom" bgColor={'#FFFBF5'} color={'black'}
                                 width={'220px'} p={2}>
                            <InfoIcon color="gray"/>
                        </Tooltip>
                    </Flex>
                </Flex>
            )
        }


        const tableBoxs = () => {
            const tableDic = {
                'reading': {
                    'title': ["Question Type", "Total", 'Correct', 'Incorrect', 'Unattempted', 'Accuracy'],
                    'color': '#CDDBFE'
                },
                'listening': {
                    'title': ["Question Type", "Total", 'Correct', 'Incorrect', 'Unattempted', 'Accuracy'],
                    'color': '#FCD9BD'
                },
                'speaking': {
                    'title': ["Task Type", "Total", 'Score', 'Notes'],
                    'color': '#AFECEF'
                },
                'writing': {
                    'title': ["Task Type", "Total", 'Score', 'Notes'],
                    'color': '#AFECEF'
                },
            }

            return (
                <Box borderTop="1px"
                     borderLeft="1px"
                     borderRight="1px"
                     borderRadius="md"
                     borderColor="gray.200"
                     overflow="hidden">
                    <Table variant="simple" size="sm">
                        <Thead>
                            <Tr bg={tableDic[section].color}>
                                {tableDic[section].title.map((item, index) => (
                                    <Th
                                        key={index}
                                        fontWeight="bold"
                                        py={3}
                                        textAlign="center"
                                        width={
                                            item === "Question Type" ? "260px" :
                                                [
                                                    "Question Type", "Total", 'Correct', 'Incorrect', 'Unattempted',
                                                    "Task Type", 'Score'
                                                ].includes(item) ? "150px" : undefined
                                        }
                                        // borderBottom="1px"
                                        borderRight={index === tableDic[section].title.length - 1 ? "none" : "1px"}
                                        borderRightColor={"gray.300"}
                                    >
                                        {item}
                                    </Th>
                                ))}
                            </Tr>
                        </Thead>
                        <Tbody>
                            {Object.entries(sectionsMockJson?.[section] || {}).map(([category, valueDic]) => (
                                <Tr key={`${section}-${category}`}>
                                    <Td borderRight="1px" borderRightColor={"gray.300"}>
                                        {t(category)}
                                    </Td>
                                    <Td isNumeric borderRight="1px" borderRightColor={"gray.300"}>
                                        {valueDic.Total}
                                    </Td>
                                    {['reading', 'listening'].includes(section) ? (
                                        <>
                                            <Td isNumeric borderRight="1px" borderRightColor={"gray.300"}>
                                                {valueDic.Correct}
                                            </Td>
                                            <Td isNumeric borderRight="1px" borderRightColor={"gray.300"}>
                                                {valueDic.Incorrect}
                                            </Td>
                                            <Td isNumeric borderRight="1px" borderRightColor={"gray.300"}>
                                                {valueDic.Unattempted}
                                            </Td>
                                            <Td isNumeric>
                                                {Math.round(Number(valueDic.Correct) / Number(valueDic.Total) * 100)}%
                                            </Td>
                                        </>
                                    ) : (
                                        <>
                                            <Td isNumeric borderRight="1px" borderRightColor={"gray.300"}>
                                                {valueDic.Score}
                                            </Td>
                                            <Td>
                                                {valueDic.Notes}
                                            </Td>
                                        </>
                                    )}
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </Box>
            )
        }


        return (
            <Box width={'100%'} bg="white" borderRadius="lg" my={4}>
                {/* 标题部分 */}
                <Flex alignItems="center" mb={4}>
                    <Text fontSize="xl" fontWeight="bold" mr={2}>
                        {t(section)}
                    </Text>
                    <Link fontSize="sm" textDecoration="underline" onClick={() => {
                        const new_url = window.location.href.split('?')[0] + `?view_section=${section}&mid=${mockId}`
                        window.open(new_url, '_blank')
                    }}>
                        view details
                    </Link>
                </Flex>
                {scoreBoxs()}
                {tableBoxs()}
            </Box>
        );
    };

    function ShareButton() {
        const [isCopied, setIsCopied] = useState(false);

        const copyToClipboard = async () => {
            try {
                await navigator.clipboard.writeText(`${window.location.href}?mid=${mockId}`);
                setIsCopied(true);
            } catch (err) {
                setIsCopied(false);
                console.error('无法复制到剪贴板', err);
                toast({
                    title: '复制失败',
                    description: "无法将链接复制到剪贴板。",
                    status: 'error',
                    duration: 2000,
                    isClosable: true,
                });
            }
        };


        return (
            <Popover>
                <PopoverTrigger>
                    <Link style={{textDecoration: 'underline'}}>Share</Link>
                </PopoverTrigger>
                <PopoverContent>
                    <PopoverCloseButton/>
                    <PopoverBody>
                        <Text fontWeight={'bold'}>Share this publication</Text>
                        <Button leftIcon={isCopied ? <FaCheck/> : <FaLink/>} width={'100%'} mt={4} mb={2}
                                borderRadius={'full'}
                                onClick={copyToClipboard}>
                            {isCopied ? 'Copied' : 'Copy Link'}</Button>
                        <Text> Others can view your responses and AI scores. Earn 200 points when you refer a friend to
                            signup for LingoLeap.</Text>
                    </PopoverBody>
                </PopoverContent>
            </Popover>
        );
    }


    const handleFeedbackSubmit = async (e) => {
        e.preventDefault();
        const details = JSON.stringify({
            mid: mockId,
            content: text,
            email: email
        });
        const formData = new FormData();
        formData.append('details', details);
        formData.append('gpt_response_gid', mockId);
        formData.append('rate', starsNum);
        await submitFeedback(formData).unwrap().then(response => {
            if (response.message === 'success') {
                toast({
                    title: t("feedback_successful"),
                    description: t("feedback_thank_you"),
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
            } else {
                toast({
                    title: t("feedback_failed"),
                    description: t("feedback_error"),
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                });
            }
        }).catch(err => {
            console.log(err)
        });
    };

    function generateTitle() {
        const question = questionContent?.['reading'];
        console.log('question', question);
        if (question) {
            if (question.tpo_num && question.tpo_num !== '-1') {
                return `TPO-${question.tpo_num}`;
            } else if (question.order_index) {
                const questionYearStr = question.question?.questionYear;
                if (questionYearStr) {
                    return `[${questionYearStr}] No. ${question.order_index}`;
                } else {
                    return `${question.order_index}`;
                }
            }
        }
        console.warn('No valid question found for generating title');
        return ''; // 默认返回空字符串，如果所有条件都不满足
    }


    return (
        <Box margin="auto" padding="1" bg={'white'} width={'1440px'} px={'142px'} py={'80px'}>
            {/*<Button colorScheme="teal">Menu >></Button>*/}
            <Flex justifyContent="space-between" alignItems="center">
                <Heading size="xl">Evaluation Results</Heading>
                <HStack spacing={4}>
                    <Button colorScheme={'write'} borderColor={'gray.300'} color={'black'} borderWidth={2}
                            leftIcon={<LuRepeat/>}
                            onClick={() => {
                                const pathParts = location.pathname.split('/'); // 分割路径
                                console.log('pathParts', pathParts)
                                let newUrl;
                                if (pathParts[3].includes('all_')) {
                                    newUrl = `/${pathParts[1]}/${pathParts[2]}/all_reading/all`
                                } else {
                                    newUrl = `/${pathParts[1]}/${pathParts[2]}/${pathParts[3]}/${pathParts[4]}`
                                }
                                console.log('newUrl', newUrl)
                                setQuestionContent({})
                                setAnswerContent({})
                                setMockJson({})
                                history.replace(newUrl)
                            }}
                    >Retake</Button>
                    <Button colorScheme={'write'} borderColor={'gray.300'} color={'black'} borderWidth={2}
                            display={mockId ? "none" : "block"}
                            leftIcon={<BsTextIndentRight style={{
                                fontWeight: 'bold',
                                transform: 'scale(1.3)', // 调整大小以增强视觉效果
                            }}/>}
                            onClick={() => {
                                history.goBack()
                            }}
                    >Back to questions</Button>
                    <Button bgColor={'#6062FF'} color={'white'} borderWidth={2} rightIcon={<FaAngleRight/>}
                            onClick={() => {
                                history.replace('/toefl/full_mock_test')
                            }}
                    >Next Test</Button>
                </HStack>
            </Flex>
            <Divider borderWidth={1} borderColor={'gray.300'} my={6}/>
            <HStack my={8}>
                <Text fontWeight={'bold'} fontSize={'xl'}>{generateTitle()}</Text>
                <Divider orientation="vertical" borderWidth={1} h={3} borderColor={'gray.300'} mx={4}/>
                <IoCalendarOutline></IoCalendarOutline>
                <Text fontWeight={'bold'}> Date taken: </Text>
                <Text>{formatDate(evaluationDate)}</Text>
                <Text color={'gray.500'}>|</Text>
                <IoShareSocialOutline></IoShareSocialOutline>
                <ShareButton></ShareButton>
                <Text color={'gray.500'}>|</Text>
                <FiPrinter></FiPrinter>
                <Link style={{textDecoration: 'underline'}} onClick={(e) => {
                    e.preventDefault();
                    window.print();
                }}>Print</Link>
            </HStack>
            <HStack mt={4} spacing={0}>
                <Box border="1px" borderRadius="6px" borderColor={'gray.300'} p="4px">
                    <IoRocketOutline style={{
                        fontWeight: 'bold'
                    }}/>
                </Box>
                <Button rightIcon={isScoresOpen ? <FaAngleUp/> : <FaAngleDown/>}
                        bgColor={'white'}
                        onClick={() => {
                            setIsScoresOpen(!isScoresOpen)
                        }}>
                    <Text fontWeight={'bold'} fontSize={'xl'}>Estimated Scores</Text>
                </Button>
            </HStack>
            {isScoresOpen && (<VStack mt={4} spacing={4}>
                <Box bg="white" borderRadius="md" p={4} width={'100%'} borderWidth={'2px'} borderColor={'teal'}>
                    <Heading size="md">Test Date: {formatDate(evaluationDate)}</Heading>
                    <Divider borderWidth={1} borderStyle="dashed" mt={3} mb={2}/>
                    <HStack spacing={8}>
                        <ScoreArc score={scores?.['total']} maxScore={30 * sections.length}/>
                        {sections.map((section, index) => {
                            return (
                                <ScoreSection key={index} section={section}></ScoreSection>
                            );
                        })}
                    </HStack>
                </Box>
                <Box bg="white" borderRadius="md" p={4} width={'100%'} borderWidth={'2px'} borderColor={'orange.400'}>
                    <Text fontSize="xl"><strong>Best Score</strong> | Your best scores from all the tests in
                        LingoLeap</Text>
                    <Divider borderWidth={1} borderStyle="dashed" my={3}/>
                    <HStack spacing={8}>
                        <Box
                            borderRadius="xl"
                            borderWidth={'2px'}
                            borderColor={'orange.400'}
                            width={'198px'}
                            height={'157px'}
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            flexDirection="column"
                            p={2}
                        >
                            <Text fontSize={'2xl'} textAlign="center" pt={1}>Sum of Highest Section Scores</Text>
                            <Text fontSize={'45px'} h="50px" alignItems="center"
                                  transform="translateY(-10px)">{bestScoreDic?.['total']}</Text>
                            <Text mb={1} fontSize={'lg'}>Out of {30 * sections.length}</Text>
                        </Box>
                        {sections.map((section, index) => {
                            return (
                                <BestScoreSection key={index} section={section}></BestScoreSection>
                            );
                        })}
                    </HStack>
                </Box>
            </VStack>)}
            <HStack mt={6} spacing={0}>
                <Box border="1px" borderRadius="6px" borderColor={'gray.300'} p="4px">
                    <AiOutlineBarChart/>
                </Box>
                <Button rightIcon={isScoreRangesOpen ? <FaAngleUp/> : <FaAngleDown/>}
                        onClick={() => {
                            setIsScoreRangesOpen(!isScoreRangesOpen)
                        }} bgColor={'white'}>
                    <Text fontWeight={'bold'}>Score Ranges</Text>
                </Button>
            </HStack>
            {isScoreRangesOpen && (<VStack spacing={4}>
                {sections.map((section, index) => {
                    return (
                        <ScoreDetailTable
                            key={index}
                            section={section}
                            score={scores?.[section] || 0}
                            maxScore={30}
                            details={[{
                                questionType: 'rhetorical_purpose',
                                total: 2,
                                correct: 2,
                                incorrect: 1,
                                unattempted: 0,
                                accuracy: 50
                            }, {
                                questionType: 'rhetorical_purpose',
                                total: 2,
                                correct: 2,
                                incorrect: 1,
                                unattempted: 0,
                                accuracy: 50
                            }, {
                                questionType: 'rhetorical_purpose',
                                total: 2,
                                correct: 2,
                                incorrect: 1,
                                unattempted: 0,
                                accuracy: 50
                            }]}
                        />
                    );
                })}
            </VStack>)}


            <Box mt={6} p={6} borderWidth={1} borderRadius="md" borderColor="gray.300"
                 bgColor={'#F7F7F7'}>
                <Text fontSize="xl" fontWeight="bold">Rate Assessment</Text>
                <Text mt={4}>Do you feel like the test accurately measured your skills?</Text>
                <HStack spacing={2} mt={1}>
                    {[...Array(5)].map((_, index) => {
                        const id = index + 1;
                        return (
                            <FaStar
                                key={id}
                                fontSize={28}
                                color={id > starsNum ? 'gray' : '#FF8A00'}
                                onClick={() => setStarsNum(id)}
                            />
                        );
                    })}
                </HStack>
                <HStack mt={4}>
                    <Text>We love feedback ❤️ </Text>
                    <Text color={'gray.400'}>(optional)</Text>
                </HStack>
                <Box mt={1}>
                    <Textarea
                        value={text}
                        width="100%"
                        height={'100px'}
                        placeholder={t("I love it!")}
                        onChange={(e) => setText(e.target.value)}
                        spellcheck="false"
                        border={'1px'}
                        borderRadius={'4px'}
                        borderColor={'gray.300'}
                        padding={'6px'}
                    />
                </Box>
                <HStack mt={2}>
                    <Text>Your email </Text>
                    <Text color={'gray.400'}>(optional)</Text>
                </HStack>
                <Box mt={1}>
                    <textarea
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Please enter your email address"
                        spellcheck="false"
                        style={{
                            width: '100%',
                            height: '35px',
                            border: '1px',
                            borderRadius: '4px',
                            borderColor: 'gray.300',
                            padding: '6px'
                        }}/>
                </Box>
                <Button mt={2} bgColor={'orange.400'} color={'black'} width={'235px'} height={'33px'} fontSize={'sm'}
                        onClick={handleFeedbackSubmit}
                >
                    Send feedback
                </Button>
            </Box>
            <Divider borderWidth={1} borderColor={'gray.300'} mt={20} mb={6}/>
            <HStack justifyContent={'center'}>
                <Text>🚀 Powered by </Text>
                <Link fontWeight={'bold'} style={{textDecoration: 'underline'}}
                      href='/toefl/reading'>LingoLeap.ai</Link>
            </HStack>

        </Box>
    );
}

export default ToeflScoreReport;
export const TOEFL_READING_SCORE = {
    20: "30",
    19: "29",
    18: "27",
    17: "25",
    16: "24",
    15: "23",
    14: "21",
    13: "20",
    12: "18",
    11: "17",
    10: "15",
    9: "14",
    8: "12",
    7: "10",
    6: "8",
    5: "6",
    4: "4",
    3: "2",
    2: "1",
    1: "0",
    0: "0"
}
export const TOEFL_LISTENING_SCORE = {
    28: "30",
    27: "29",
    26: "28",
    25: "27",
    24: "26",
    23: "25",
    22: "24",
    21: "23",
    20: "22",
    19: "21",
    18: "19",
    17: "18",
    16: "17",
    15: "16",
    14: "15",
    13: "13",
    12: "12",
    11: "11",
    10: "10",
    9: "8",
    8: "7",
    7: "5",
    6: "4",
    5: "3",
    4: "2",
    3: "1",
    2: "0",
    1: "0",
    0: "0"
}