import {Box, Heading, Text, VStack} from '@chakra-ui/react';
import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import {ToeflIbtContext} from "./ToeflIbtContext";

function ToeflSpeakingText() {
    const {partIdx} = useParams();
    const {
        questionContent,
    } = React.useContext(ToeflIbtContext)
    const [speakingTexts, setSpeakingTexts] = useState([]);
    const [timeLeft, setTimeLeft] = useState(45);
    const history = useHistory();
    const [isTimerRunning, setIsTimerRunning] = useState(false);

    useEffect(() => {
        // console.log("questionContent", questionContent)
        console.log('questionContent', questionContent)
        const texts = questionContent?.['speaking']?.tasks[partIdx - 1].reading_texts;
        let flattenedTexts = []
        if (typeof texts === 'string') {
            flattenedTexts = texts.split('\n')
        } else if (Array.isArray(texts)) {
            flattenedTexts = texts?.flatMap(text => text.split('\n'));
        }
        // console.log(flattenedTexts);
        setSpeakingTexts(flattenedTexts)
    }, [questionContent, partIdx]);

    useEffect(() => {
        if (timeLeft > 0) {
            const timer = setInterval(() => {
                setTimeLeft((prevTime) => prevTime - 1);
            }, 1000);
            setIsTimerRunning(true);
            return () => clearInterval(timer);
        } else {
            const url = location.pathname.replace("/text", "/audio");
            history.replace(url);
        }
    }, [timeLeft]);


    return (
        <Box maxWidth="860px" margin="auto" padding="4">
            <VStack spacing={8} align="stretch">
                <Text fontSize="lg">Reading Time: {timeLeft} Seconds</Text>
                <Heading size="md" style={{ textAlign: 'center' }}>{speakingTexts && speakingTexts[0]}</Heading>
                <VStack spacing={4} align="stretch" p={3}>
                    {speakingTexts && speakingTexts.map((text, id) => {
                        if (id === 0) {
                            return
                        }
                        return <div key={id}
                                    dangerouslySetInnerHTML={{__html: text}}
                        />
                    })}
                </VStack>
            </VStack>
        </Box>
    );
}

export default ToeflSpeakingText;
