import React, {useEffect, useRef, useState} from 'react';
import {ToeflIbtContext} from "./ToeflIbtContext";
import {
    Box,
    Button,
    Checkbox,
    CheckboxGroup,
    Divider,
    Flex,
    HStack,
    Icon,
    Link,
    Radio,
    RadioGroup,
    Tab,
    TabList,
    Tabs,
    Text,
    useDisclosure,
    VStack
} from '@chakra-ui/react';
import {FaVolumeHigh} from "react-icons/fa6";
import {FaAngleDown, FaAngleUp, FaCheck, FaTimes} from "react-icons/fa";
import {useTranslation} from "react-i18next";

function ToeflListeningAnswer() {
    const [partIdx, setPartIdx] = useState(1);
    const [subIdx, setSubIdx] = useState('1');
    const [question, setQuestion] = useState(null);
    const {questionContent, answerContent, volume} = React.useContext(ToeflIbtContext);
    const [audioPath, setAudioPath] = React.useState('');
    const [questionAudio, setQuestionAudio] = useState('');
    const {isOpen, onToggle} = useDisclosure({defaultIsOpen: true});
    const audioRef = useRef(null);
    const partAudioRef = useRef(null);
    const [taskAnswer, setTaskAnswer] = useState(null);
    const {t, i18n} = useTranslation();

    useEffect(() => {
        const taskObj = questionContent?.['listening']?.tasks[partIdx - 1];
        if (taskObj) {
            const questions = taskObj.questions;
            const answers = {};
            questions.forEach((q) => {
                answers[q.sub_question_idx] = q.sub_question_answer; // 假设每个问题对象有一个 answer 属性
            });
            setTaskAnswer(answers);
            setSubIdx(questions[0].sub_question_idx.toString());
            setAudioPath(taskObj.listening_audio)
        }
    }, [partIdx, questionContent]);


    useEffect(() => {
        getQuestionInfo();
    }, [subIdx]);

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.volume = volume;
        }
        if (partAudioRef.current) {
            partAudioRef.current.volume = volume;
        }
    }, [volume]);

    function getQuestionInfo() {
        const taskObj = questionContent?.['listening']?.tasks?.[partIdx - 1];
        if (taskObj && taskObj.questions) {
            for (const questionObj of taskObj.questions) {
                if (questionObj.sub_question_idx.toString() === subIdx.toString()) {
                    setQuestion(questionObj);
                    console.log(questionObj);

                    if (questionObj.sub_question_audio && questionObj.sub_question_audio.audio_url) {
                        setQuestionAudio(questionObj.sub_question_audio.audio_url);
                    }
                    return;
                }
            }
        }
    }

    function isEqual(answer1, answer2) {
        const preprocess = (value) => {
            // 听力有顺序题
            if (Array.isArray(value)) {
                value = value.join("");
            }
            return value;
        };
        const processedAnswer1 = preprocess(answer1 || '');
        const processedAnswer2 = preprocess(answer2 || '');
        return processedAnswer1 === processedAnswer2;
    }


    const togglePlay = () => {
        if (!audioRef.current.playing) {
            audioRef.current.play();
        }
    };

    return (
        <Box margin="auto" padding="1" bg={'gray.100'} height={"92vh"} width={'100%'}>
            <Tabs variant='enclosed' bg={'white'} borderRadius={'md'}>
                <TabList>
                    {questionContent?.['listening']?.tasks.map((task, index) => (
                        <Tab key={index} onClick={() => setPartIdx(index + 1)}>
                            Part {index + 1}
                        </Tab>
                    ))}
                </TabList>
            </Tabs>
            <Divider borderWidth={1}/>
            <Flex direction='row' height={'100%'}>
                <Box width={'50%'} p={2} borderRadius={'md'} bg={'white'} height={'100%'}>
                    <Flex direction={'column'} height={'100%'}>
                        <HStack mb={2}>
                            <audio
                                src={audioPath}
                                controls={true}
                                ref={partAudioRef}
                            />
                            <Button ml={4} as={Link} onClick={onToggle} variant="link"
                                    rightIcon={isOpen ? <FaAngleUp/> : <FaAngleDown/>}>
                                {t("listening_text")}
                            </Button>
                        </HStack>
                        {isOpen && (
                            <Box overflowY="auto" p={2} border="2px"
                                 borderColor="gray.200"
                                 borderRadius="16"
                                 height="100%"
                                 width="100%"
                                 flex='1'>
                                <Text mt={4}>
                                    {questionContent?.['listening']?.tasks?.[partIdx - 1]?.listening_texts?.map((text, index) => (
                                        <Text key={index}>{text}</Text>
                                    ))}
                                </Text>
                            </Box>
                        )}
                    </Flex>
                </Box>
                <Box width={'50%'} p={2} borderRadius={'md'} bg={'white'}>
                    <Box>
                        {questionContent?.['listening']?.tasks?.[partIdx - 1]?.questions?.map(q => (
                            <Button key={q.sub_question_idx} onClick={() => {
                                console.log(q.sub_question_idx)
                                setSubIdx(q.sub_question_idx.toString());
                            }}
                                    mr={3}
                                    bg={subIdx === q.sub_question_idx.toString() ? 'teal' : 'gray.300'} // 背景色变更
                                    color={subIdx === q.sub_question_idx.toString() ? 'white' : 'black'} // 文本颜色变更
                                    size='sm'
                            >
                                Q {q.sub_question_idx}
                                {taskAnswer?.[q.sub_question_idx] !== undefined &&
                                isEqual(answerContent?.["listening"]?.[q.sub_question_idx], taskAnswer[q.sub_question_idx]) ? (
                                        <Box
                                            position="absolute"
                                            top={0}
                                            right={0}
                                            mt={-2}
                                            mr={-2}
                                            color={'green.300'}>
                                            <Icon as={FaCheck} fontSize={'xl'}/>
                                        </Box>
                                    ) :
                                    (
                                        <Box
                                            position="absolute"
                                            top={0}
                                            right={0}
                                            mt={-2}
                                            mr={-2}
                                            textColor={'red.300'}
                                        >
                                            <Icon as={FaTimes} fontSize={'xl'}/>
                                        </Box>
                                    )}
                            </Button>
                        ))}
                    </Box>
                    {question && (
                        <Box mt={8}>
                            <Flex direction={'row'} alignItems={'center'}>
                                <Text fontSize={'md'} mr={2}>{t("problem_audio")} </Text>
                                <audio
                                    src={questionAudio}
                                    ref={audioRef}
                                    style={{display: 'none'}} // 隐藏音频控件
                                />
                                <FaVolumeHigh
                                    onClick={togglePlay}
                                    color={'teal'}
                                    fontSize={25}
                                />
                            </Flex>
                            <VStack mt={8} spacing={4} align="stretch">
                                <Text fontSize="lg" fontWeight="bold"
                                      mb={2}>{question?.sub_question_texts}</Text>
                                {(question.sub_question_answer.length > 1) ? (
                                    <CheckboxGroup isDisabled>
                                        <VStack align="start" spacing={2}>
                                            {question.sub_question_options && Object.entries(question.sub_question_options).map(([key, option]) => (
                                                <Checkbox key={key} value={key}>
                                                    {option}
                                                </Checkbox>
                                            ))}
                                        </VStack>
                                    </CheckboxGroup>
                                ) : (
                                    <RadioGroup isDisabled>
                                        <VStack align="start" spacing={2}>
                                            {question.sub_question_options && Object.entries(question.sub_question_options).map(([key, option]) => (
                                                <Radio key={key} value={key}>{option}</Radio>
                                            ))}
                                        </VStack>
                                    </RadioGroup>)}
                            </VStack>
                            <Flex direction={"row"} mt={8} fontSize={'xl'}>
                                <Text mr={2}>{t("your_answer")}:</Text>
                                <Text
                                    color={isEqual(answerContent?.["listening"]?.[subIdx], question.sub_question_answer) ? 'green' : 'red'}>
                                    {answerContent?.["listening"]?.[subIdx] || t("unanswered")}
                                </Text>
                            </Flex>
                            <Text fontSize={'xl'} color={'teal'}
                                  mt={2} mb={2}>{t("correct_answer")}: {question.sub_question_answer}</Text>
                            {i18n.language === 'cn' && <Text>解析: {question.sub_question_analysis}</Text>}
                        </Box>
                    )}
                </Box>
            </Flex>
        </Box>
    );
}

export default ToeflListeningAnswer;
