import React, {useState} from "react";
import {
    Box,
    Button, Center, Checkbox, Flex, HStack, Link,
    Popover,
    PopoverArrow, PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverHeader,
    PopoverTrigger, Text, useBreakpointValue
} from "@chakra-ui/react";
import {useSelector} from "react-redux";
import { useTranslation } from 'react-i18next';
import {selectMarkdownResponse, selectRightSideContent, selectToeflData} from "../redux/toeflSlice";

export function ShareResultButton(props) {
    var {gid, visible,share_url=null, ...other} = props;
    const toeflData = useSelector(selectToeflData);
    const [linkCopied, setLinkCopied] = useState(false);
    const isMobile = useBreakpointValue({ base: true, md: false });
    const { t, i18n } = useTranslation();

    const getLink = () => {
        if (share_url){
            return window.location.origin + share_url;
        } else if (gid) {
            return window.location.origin + "/share/" + gid;
        } else {
            return window.location.origin + "/share/" + toeflData.gid;
        }
    }

    return (
        <Popover isLazy trigger="hover" placement='top'>
            <PopoverTrigger>
                <Button colorScheme='yellow' borderRadius='20px' size={isMobile? 'sm': 'md'}
                        display={visible ? "block" : "none"}>{t("share_grading_result")}</Button>
            </PopoverTrigger>
            <PopoverContent>
                <PopoverArrow/>
                <PopoverCloseButton/>
                <PopoverHeader>{t("ai_test_prep_expert")}</PopoverHeader>
                <PopoverBody>
                    <Flex flexDirection="column">
                        <Text>
                            {t("share_this_link")}
                            <Link href={getLink()}  isExternal color='blue.500'>{getLink()}</Link>
                            <br/>
                            { t("share_result_message")}
                        </Text>
                        <Center>
                            <Button
                                colorScheme='blue'
                                onClick={() => {
                                    navigator.clipboard.writeText(getLink());
                                    setLinkCopied(true);
                                    setTimeout(() => {
                                        setLinkCopied(false);
                                    }, "2000");
                                }}
                            >
                                {linkCopied ? t("already_copied") : t("copy_link")}
                            </Button>
                        </Center>
                    </Flex>
                </PopoverBody>
            </PopoverContent>
        </Popover>
    );
}