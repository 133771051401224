import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import authHeader from '../../services/auth-header';
import { useTranslation } from 'react-i18next';
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
    Checkbox,
    Text,
    useDisclosure
} from '@chakra-ui/react';
import AuthService from '../../services/auth.service';
import aichatPopupBg from '../../assets/img/aichat_popup_bg.png';
import { motion, AnimatePresence } from 'framer-motion';
import { useUpdateUserPreferencesMutation, useGetUserPreferencesQuery } from "../../services/cmApi";

// Mock data for chat history
const MOCK_MESSAGES = [
    {
        content: "Hi, I am Gamer Natasa the ultimate gamer student, I really enjoy the video games. What is your favorite video games.",
        sender: 'ai',
        timestamp: '2024-03-20T10:00:00.000Z'
    },
    {
        content: "I like the Super Danganronpa RPG series the best.",
        sender: 'user',
        timestamp: '2024-03-20T10:01:00.000Z'
    },
    {
        content: "That's awesome. The world, the characters, the music, the story... Everything in it is perfect.",
        sender: 'ai',
        timestamp: '2024-03-20T10:02:00.000Z'
    },
    {
        content: "Okay!",
        sender: 'user',
        timestamp: '2024-03-20T10:03:00.000Z'
    },
    {
        content: "Hm... Why are you making that face, anyway?",
        sender: 'ai',
        timestamp: '2024-03-20T10:04:00.000Z'
    }
];

const MOCK_CHARACTER_INFO = {
    name: 'Gamer Natasa',
    avatar: '/ai/leapy.svg',
    description: "I'm the ultimate gamer student."
};

export default function AIChatHistory({ character, onClose }) {
    const history = useHistory();
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [newMessage, setNewMessage] = useState('');
    const [sessionId, setSessionId] = useState(null);
    const [characterInfo, setCharacterInfo] = useState({
        name: character?.name || 'AI Character',
        avatar: character?.img || '/ai/leapy.svg',
        description: character?.desc || "I'm an AI character."
    });
    const [showScrollDown, setShowScrollDown] = useState(false);
    const messagesEndRef = React.useRef(null);
    const scrollContainerRef = React.useRef(null);
    const [hasMore, setHasMore] = useState(true);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [oldestMessageId, setOldestMessageId] = useState(null);
    const MESSAGES_PER_PAGE = 20;
    const [sessions, setSessions] = useState({});
    const NUM_SESSIONS = 3; // Number of recent sessions to load
    const [userHasScrolled, setUserHasScrolled] = useState(false);
    const [revisedMessages, setRevisedMessages] = useState({});
    const [phoneCallWindowOpened, setPhoneCallWindowOpened] = useState(false);
    const [isAiResponding, setIsAiResponding] = useState(false);
    const [isPhoneCallAlertOpen, setIsPhoneCallAlertOpen] = useState(false);
    const [silencePhoneCallAlert, setSilencePhoneCallAlert] = useState(true);
    const cancelRef = React.useRef();
    const { t } = useTranslation();
    const { isOpen, onOpen, onClose: onCloseAuth } = useDisclosure();
    const [showLoginDialog, setShowLoginDialog] = useState(true);
    const [updateUserPreferencesRequest, isUpdateUserPreferencesError] = useUpdateUserPreferencesMutation();
    let preferences = useGetUserPreferencesQuery({});

    // Modify authentication check effect
    useEffect(() => {
        const currentUser = AuthService.getCurrentUser();
        if (!currentUser) {
            onOpen();
        }
    }, [onOpen]);

    // Define fetchInitialHistory first
    const fetchInitialHistory = async () => {
        try {
            const API_URL = process.env.REACT_APP_CM_BACKEND_URL ? process.env.REACT_APP_CM_BACKEND_URL : window.location.origin;
            
            const response = await fetch(
                `${API_URL}/api/chat/history?character_id=${character.id}&page=1&per_page=${MESSAGES_PER_PAGE}`, 
                { headers: authHeader() }
            );

            if (!response.ok) throw new Error('Failed to fetch chat history');

            const data = await response.json();
            const formattedMessages = formatMessages(data.messages);
            setMessages(formattedMessages);
            setHasMore(data.has_more);
            setLoading(false);

            if (formattedMessages.length > 0) {
                setOldestMessageId(formattedMessages[0].id);
            }
        } catch (err) {
            console.error('Error fetching history:', err);
            setError(err.message);
            setLoading(false);
        }
    };

    // Visibility change useEffect
    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible' && phoneCallWindowOpened) {
                if (character?.id) {
                    fetchInitialHistory();
                }
                setPhoneCallWindowOpened(false);
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [character, phoneCallWindowOpened]);

    // Add the shouldShowChargeAlert function
    const shouldShowChargeAlert = () => {
        if (preferences.status !== "fulfilled") {
            return true;
        }

        if (preferences.data && "shouldSilence_phone_call" in preferences.data) {
            return !preferences.data["shouldSilence_phone_call"];
        }

        return true;
    };

    // Update handlePhoneCall function
    const handlePhoneCall = () => {
        if (!AuthService.getCurrentUser()) {
            setShowLoginDialog(true);
            return;
        }
        if (shouldShowChargeAlert()) {
            setIsPhoneCallAlertOpen(true);
        } else {
            confirmPhoneCall();
        }
    };

    const confirmPhoneCall = () => {
        // Update user preferences if checkbox is checked
        if (silencePhoneCallAlert) {
            let prefs = {
                "shouldSilence_phone_call": true
            };
            updateUserPreferencesRequest(prefs)
                .then((response) => response.json())
                .then((data) => {
                    console.log("Updated user preferences:", data);
                })
                .catch(console.error);
        }

        const currentDomain = window.location.hostname;
        const baseUrl = currentDomain === 'localhost'
            ? 'http://localhost:3000'
            : `https://live.${currentDomain.replace('app.', '')}`;

        setPhoneCallWindowOpened(true);
        setIsPhoneCallAlertOpen(false);
        window.open(`${baseUrl}/?character=${character?.id}`, '_blank');
    };

    // Start chat session when component mounts
    useEffect(() => {
        const startChatAndLoadHistory = async () => {
            try {
                setLoading(true);
                const API_URL = process.env.REACT_APP_CM_BACKEND_URL ? process.env.REACT_APP_CM_BACKEND_URL : window.location.origin;
                
                // First start the chat
                const startResponse = await fetch(`${API_URL}/api/chat/start`, {
                    method: 'POST',
                    headers: {
                        'Authorization': authHeader().Authorization,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        character_id: character?.id
                    })
                });

                if (!startResponse.ok) throw new Error('Failed to start chat');

                const startData = await startResponse.json();
                setSessionId(startData.session_id);

                // Then fetch the history
                await fetchInitialHistory();
                
            } catch (err) {
                console.error('Error:', err);
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        const currentUser = AuthService.getCurrentUser();
        if (character?.id && currentUser) {
            startChatAndLoadHistory();
        }
    }, [character]);

    // Load more history
    const handleScroll = async () => {
        const container = scrollContainerRef.current;
        if (!container) return;

        const { scrollTop, scrollHeight, clientHeight } = container;
        const distanceFromBottom = scrollHeight - scrollTop - clientHeight;
        
        // Show scroll button if we're more than 100px from bottom and user has scrolled
        if (userHasScrolled && distanceFromBottom > 100) {
            setShowScrollDown(true);
        } else {
            setShowScrollDown(false);
        }

        // Load more logic
        if (scrollTop === 0 && hasMore && !isLoadingMore) {
            setIsLoadingMore(true);
            try {
                const API_URL = process.env.REACT_APP_CM_BACKEND_URL ? process.env.REACT_APP_CM_BACKEND_URL : window.location.origin;
                
                const nextPage = Math.floor(messages.length / MESSAGES_PER_PAGE) + 1;
                const response = await fetch(
                    `${API_URL}/api/chat/history?character_id=${character.id}&page=${nextPage}&per_page=${MESSAGES_PER_PAGE}`,
                    { headers: authHeader() }
                );

                if (!response.ok) throw new Error('Failed to load more messages');

                const data = await response.json();
                const newMessages = formatMessages(data.messages);

                if (newMessages.length > 0) {
                    // Preserve scroll position
                    const prevHeight = container.scrollHeight;
                    
                    setOldestMessageId(newMessages[0].id);
                    setMessages(prev => [...newMessages, ...prev]);
                    setHasMore(data.has_more);

                    // Adjust scroll position
                    requestAnimationFrame(() => {
                        container.scrollTop = container.scrollHeight - prevHeight;
                    });
                } else {
                    setHasMore(false);
                }
            } catch (err) {
                console.error('Error loading more messages:', err);
            } finally {
                setIsLoadingMore(false);
            }
        }
    };

    // Track when user manually scrolls
    const handleUserScroll = (e) => {
        const container = e.target;
        const { scrollTop, scrollHeight, clientHeight } = container;
        const distanceFromBottom = scrollHeight - scrollTop - clientHeight;

        // Only set userHasScrolled if we're not at the bottom
        if (distanceFromBottom > 100) {
            setUserHasScrolled(true);
        } else {
            setUserHasScrolled(false);
        }
        
        handleScroll();
    };

    // Format messages helper with session info
    const formatMessages = (messages) => {
        return messages.map(msg => ({
            id: msg.id,
            content: msg.content,
            sender: msg.role === 'assistant' ? 'ai' : 'user',
            timestamp: msg.created_at,
            session_id: msg.session_id
        }));
    };

    const handleReviseText = async (messageId, text) => {
        try {
            // If already revised, just toggle visibility
            if (revisedMessages[messageId]) {
                setRevisedMessages(prev => ({
                    ...prev,
                    [messageId]: {
                        ...prev[messageId],
                        isOpen: !prev[messageId].isOpen
                    }
                }));
                return;
            }

            const API_URL = process.env.REACT_APP_CM_BACKEND_URL ? process.env.REACT_APP_CM_BACKEND_URL : window.location.origin;
            
            const response = await fetch(`${API_URL}/api/revise_text`, {
                method: 'POST',
                headers: {
                    'Authorization': authHeader().Authorization,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ text })
            });

            if (!response.ok) throw new Error('Failed to revise text');

            const data = await response.json();
            setRevisedMessages(prev => ({
                ...prev,
                [messageId]: {
                    text: data.revised + (data.is_cropped ? '...' : ''),
                    isOpen: true
                }
            }));
        } catch (err) {
            console.error('Error revising text:', err);
        }
    };

    // Optional: Add session separator in the UI
    const renderMessage = (message) => {
        return (
            <React.Fragment key={message.id}>
                <div className={`flex ${message.sender === 'user' ? 'justify-end' : 'justify-start'} mb-4`}>
                    <div className={`flex flex-col ${message.sender === 'user' ? 'items-end' : 'items-start'} ${message.sender === 'user' ? 'max-w-[640px]' : 'max-w-[640px]'}`}>
                        <div className="bg-[#F3F4F6] p-3 rounded-lg w-full">
                            {message.content}
                            {message.sender === 'user' && (
                                <>
                                    <div className="flex justify-end">
                                        <button
                                            onClick={() => handleReviseText(message.id, message.content)}
                                            className="flex items-center gap-1 text-sm text-gray-500 my-2"
                                        >
                                            <span className="w-5 h-5">✨</span> 
                                            Revise 
                                            {revisedMessages[message.id] ? 
                                                <FaChevronUp className="ml-1" /> : 
                                                <FaChevronDown className="ml-1" />
                                            }
                                        </button>
                                    </div>
                                    {revisedMessages[message.id]?.isOpen && (
                                        <>
                                            <div className="w-full border-t border-gray-200 my-2"></div>
                                            <div className="text-gray-600">
                                                {revisedMessages[message.id].text}
                                            </div>
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>
                {isAiResponding && message === messages[messages.length - 1] && message.sender === 'user' && (
                    <div className="flex justify-start mb-4">
                        <div className="flex flex-col items-start max-w-[320px]">
                            <div className="bg-[#F3F4F6] p-3 rounded-lg w-full flex items-center gap-2">
                                <div className="w-2 h-2 bg-gray-500 rounded-full animate-bounce" style={{ animationDelay: '0ms' }}></div>
                                <div className="w-2 h-2 bg-gray-500 rounded-full animate-bounce" style={{ animationDelay: '150ms' }}></div>
                                <div className="w-2 h-2 bg-gray-500 rounded-full animate-bounce" style={{ animationDelay: '300ms' }}></div>
                            </div>
                        </div>
                    </div>
                )}
            </React.Fragment>
        );
    };

    const handleSendMessage = async (e) => {
        e.preventDefault();
        if (!AuthService.getCurrentUser()) {
            setShowLoginDialog(true);
            return;
        }
        if (!newMessage.trim() || !sessionId) return;

        const userMessage = {
            content: newMessage,
            sender: 'user',
            timestamp: new Date().toISOString(),
            avatar: characterInfo.avatar
        };
        setMessages(prev => [...prev, userMessage]);
        setNewMessage('');
        setIsAiResponding(true);

        // Scroll to bottom after user message
        setTimeout(() => scrollToBottom(), 100);

        try {
            const API_URL = process.env.REACT_APP_CM_BACKEND_URL ? process.env.REACT_APP_CM_BACKEND_URL : window.location.origin;
            
            const response = await fetch(`${API_URL}/api/chat/send`, {
                method: 'POST',
                headers: {
                    'Authorization': authHeader().Authorization,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    session_id: sessionId,
                    message: newMessage
                })
            });

            if (!response.ok) throw new Error('Failed to send message');

            const data = await response.json();
            const aiMessage = {
                content: data.response,
                sender: 'ai',
                timestamp: new Date().toISOString(),
                avatar: characterInfo.avatar
            };
            setMessages(prev => [...prev, aiMessage]);
            
            // Scroll to bottom after AI response
            setTimeout(() => scrollToBottom(), 100);
        } catch (err) {
            console.error('Error sending message:', err);
        } finally {
            setIsAiResponding(false);
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSendMessage(e);
        }
    };

    // Scroll to bottom function - don't trigger scroll button
    const scrollToBottom = () => {
        if (scrollContainerRef.current) {
            setUserHasScrolled(false); // Reset user scroll state
            const scrollContainer = scrollContainerRef.current;
            const scrollHeight = scrollContainer.scrollHeight;
            const height = scrollContainer.clientHeight;
            const maxScrollTop = scrollHeight - height;
            
            scrollContainer.scrollTo({
                top: maxScrollTop,
                behavior: 'smooth'
            });
        }
    };

    // Update scroll event listener
    useEffect(() => {
        const scrollContainer = scrollContainerRef.current;
        if (scrollContainer) {
            scrollContainer.addEventListener('scroll', handleUserScroll);
            return () => scrollContainer.removeEventListener('scroll', handleUserScroll);
        }
    }, [hasMore, isLoadingMore, oldestMessageId]);

    // Auto scroll to bottom when new messages arrive
    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    // Modify the LoginDialog component
    const LoginDialog = () => (
        <motion.div 
            className="bg-[#1C1C1C] text-white p-8 rounded-lg text-center w-full max-w-[2000px] mx-4 relative" 
            style={{ aspectRatio: '16/9' }}
        >
            {/* Close button */}
            <button 
                onClick={() => {
                    onCloseAuth();
                    setShowLoginDialog(false);
                }}
                className="absolute top-4 right-4 text-gray-400 hover:text-white transition-colors duration-200"
            >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
            </button>

            <div className="relative z-10">
                <h2 className="text-4xl font-bold mb-4 mt-10">
                    Immerse yourself in
                </h2>
                <h3 className="text-4xl font-bold mb-8">
                    interactive entertainment
                </h3>
                
                <Button
                    onClick={() => {
                        const currentUrl = window.location.href;
                        history.push(`/login?redirect=${encodeURIComponent(currentUrl)}`);
                    }}
                    bgColor="#FF8A00"
                    color="black"
                    _hover={{ bgColor: "#FF9D2C" }}
                    px="8"
                    py="3"
                    borderRadius="6px"
                    fontSize="lg"
                    fontWeight="medium"
                    mb="32"
                >
                    Start chatting for free
                </Button>
            </div>

            {/* Image container */}
            <div className="absolute left-0 right-0 bottom-0 rounded-b-lg overflow-hidden">
                <img 
                    src={aichatPopupBg} 
                    alt="Character showcase"
                    className="w-full"
                    style={{ maxWidth: 'none' }}
                />
            </div>
        </motion.div>
    );

    // Modify the render check to wrap the main content instead of replacing it
    return (
        <>
            {!AuthService.getCurrentUser() && (
                <AnimatePresence>
                    {showLoginDialog && (
                        <motion.div 
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.2 }}
                            className="fixed inset-0 bg-black/80 backdrop-blur-sm z-50 flex items-center justify-center p-4"
                        >
                            <motion.div 
                                initial={{ scale: 0.95, opacity: 0 }}
                                animate={{ scale: 1, opacity: 1 }}
                                exit={{ scale: 0.95, opacity: 0 }}
                                transition={{ duration: 0.2, delay: 0.1 }}
                                className="relative w-[65vw] max-w-[2000px]"
                            >
                                <LoginDialog />
                            </motion.div>
                        </motion.div>
                    )}
                </AnimatePresence>
            )}
            
            <div className={(!AuthService.getCurrentUser() && showLoginDialog) ? 'pointer-events-none' : ''}>
                <div className="flex flex-col h-screen bg-white">
                    {/* Fixed Header */}
                    <div className="flex items-center justify-between p-4 border-b w-full h-[70px]">
                        <div className="flex items-center ml-[100px]">
                            <div className="w-10 h-10 rounded-full overflow-hidden mr-3">
                                <img 
                                    src={characterInfo.avatar} 
                                    alt={characterInfo.name}
                                    className="w-full h-full object-cover"
                                />
                            </div>
                            <span className="font-medium">{characterInfo.name}</span>
                        </div>
                        <div className="flex items-center gap-4 mr-[100px]">
                            <button 
                                onClick={onClose}
                                className="px-4 py-1 rounded-full border border-gray-300 hover:bg-gray-50"
                            >
                                Exit
                            </button>
                        </div>
                    </div>

                    {/* Scrollable Content Container */}
                    <div 
                        ref={scrollContainerRef}
                        className="flex-1 overflow-y-auto"
                        style={{ height: 'calc(100vh - 70px - 95px)' }}
                    >
                        <div className="max-w-[800px] mx-auto"> {/* Added max-width container */}
                            {/* Character Profile */}
                            <div className="flex flex-col items-center py-8 bg-white">
                                <div className="relative w-40 h-40 rounded-full overflow-hidden mb-4">
                                    <img 
                                        src={characterInfo.avatar}
                                        alt={characterInfo.name}
                                        className="absolute inset-0 w-full h-full object-cover"
                                    />
                                </div>
                                <h2 className="text-xl font-bold mb-2">{characterInfo.name}</h2>
                                <p className="text-gray-600">{characterInfo.description}</p>
                            </div>

                            {/* Chat Messages */}
                            <div className="px-4">
                                {isLoadingMore && (
                                    <div className="text-center py-2">
                                        <span className="text-gray-500">Loading more messages...</span>
                                    </div>
                                )}

                                {messages.map(message => renderMessage(message))}
                                <div className="h-32"></div>
                            </div>
                        </div>

                        {/* Scroll down button */}
                        {showScrollDown && (
                            <button
                                onClick={scrollToBottom}
                                className="fixed bottom-32 right-8 bg-white shadow-lg rounded-full p-3 flex items-center gap-2 border border-gray-200 hover:bg-gray-50 transition-all duration-200 z-50"
                            >
                                <FaChevronDown className="text-gray-600" />
                                <span className="text-sm text-gray-600">Scroll down</span>
                            </button>
                        )}
                    </div>

                    {/* Fixed Input Box */}
                    <div className="fixed bottom-0 left-0 right-0 bg-white border-t z-40 h-[95px]">
                        <div className="flex justify-center py-1">
                            <div className="w-full max-w-[800px]">
                                <div className="flex items-center gap-4">
                                    <div className="relative flex-1">
                                        <input
                                            type="text"
                                            value={newMessage}
                                            onChange={(e) => setNewMessage(e.target.value)}
                                            onKeyPress={handleKeyPress}
                                            placeholder={`Message ${characterInfo.name}...`}
                                            className="w-full p-3 pr-16 rounded-full bg-gray-100 focus:outline-none"
                                        />
                                        <div className="absolute right-2 top-1/2 -translate-y-1/2">
                                            <button 
                                                onClick={handleSendMessage}
                                                className="p-2 rounded-full bg-black"
                                            >
                                                <svg className="w-6 h-6 text-white transform rotate-90" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                    <button 
                                        onClick={handlePhoneCall}
                                        className="p-2 rounded-full border border-gray-300"
                                    >
                                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                                        </svg>
                                    </button>
                                </div>
                                <div className="text-center text-xs text-gray-500 mt-1">
                                    Content generated by AI cannot fully guarantee authority
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <AlertDialog
                isOpen={isPhoneCallAlertOpen}
                leastDestructiveRef={cancelRef}
                onClose={() => setIsPhoneCallAlertOpen(false)}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            {t("kind_notice")}
                        </AlertDialogHeader>
                        <AlertDialogBody>
                            <Text>
                                {t("this_will_cost")} 15 {t("credits")} / min
                            </Text>
                            <Text fontSize={'sm'} style={{color: "#2e8ae6", fontWeight: "bold"}}>
                                <a href='/purchase'>{t("purchase_credits")}</a>
                            </Text>
                            <Checkbox
                                isChecked={silencePhoneCallAlert}
                                onChange={(e) => setSilencePhoneCallAlert(e.target.checked)}
                                style={{paddingTop: "10px"}}
                            >
                                {t("dont_remind_me_again")}
                            </Checkbox>
                        </AlertDialogBody>
                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={() => setIsPhoneCallAlertOpen(false)}>
                                {t("cancel")}
                            </Button>
                            <Button colorScheme='green' onClick={confirmPhoneCall} ml={3}>
                                {t("continue")}
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    );
}