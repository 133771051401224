import React, {useEffect, useState} from "react";
import {useLocation, useParams, useRouteMatch} from "react-router-dom";


export const ToeflIbtContext = React.createContext();

export const ToeflProvider = ({children}) => {
    const [questionContent, setQuestionContent] = useState(null); // 获取题目内容
    const [answerContent, setAnswerContent] = useState({}); // 暂存回答内容
    const [mockJson, setMockJson] = useState({}); // 暂存模考题型统计
    const initialVolume = localStorage.getItem('volume') ? parseFloat(localStorage.getItem('volume')) : 0.5;
    const [volume, setVolume] = useState(initialVolume); // 存放音量大小
    const [mockId, setMockId] = useState(null); // 存放音量大小

    useEffect(() => {
        localStorage.setItem('volume', volume);
    }, [volume]);

    const value = {
        questionContent,
        setQuestionContent,
        answerContent,
        setAnswerContent,
        volume,
        setVolume,
        mockJson,
        setMockJson,
        setMockId,
        mockId
    };

    return (
        <ToeflIbtContext.Provider value={value}>
            {children}
        </ToeflIbtContext.Provider>
    );
};

export const PracticeTypeEnum = {
    FULL_MOCK_TEST: 'full_mock_test',
    MOCK_TEST: 'mock_test',
    PRACTICE: 'practice'
};


export const getRouteInfo = () => {
    const parentPath = useRouteMatch().url; // 获取 RouteMatch
    const {tpo_idx, section_str, qid} = useParams();  // 获取 Params
    const [questionSet, tpoNum] = tpo_idx.split('_'); // tpo_idx -- "questionBack_*"
    // console.log('tpo_idx', {tpo_idx})
    // console.log('tpoNum', {tpoNum})
    const section = section_str.split('_').pop();
    const practiceType = section_str.includes('_')
        ? PracticeTypeEnum.FULL_MOCK_TEST
        : qid === "all"
            ? PracticeTypeEnum.MOCK_TEST
            : PracticeTypeEnum.PRACTICE;
    const location = useLocation(); // 获取当前路径信息
    const pathParts = location.pathname.split('/'); // 分割路径
    const childPath = pathParts.length > 5 ? pathParts[5] : null; // 检查并获取最后一部分
    const otherParams = pathParts.length > 5 ? pathParts.slice(6) : null;
    // console.log(parentPath, tpo_num, qid, childPath, otherParams)
    const searchParams = new URLSearchParams(location.search); // 获取查询参数
    const answerJsonString = searchParams.get('answer_json'); // 获取 answer_json 参数
    const mid = searchParams.get('mid'); // 获取 answer_json 参数
    const answerJson = answerJsonString ? JSON.parse(decodeURIComponent(answerJsonString)) : null;
    return {parentPath, questionSet, tpoNum, section, practiceType, qid, childPath, otherParams, answerJson, mid: mid};
};