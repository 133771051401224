import {Box, Center, Divider, Flex, Icon, Text, useToast, VStack} from '@chakra-ui/react';
import React, {useEffect, useRef, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {ToeflIbtContext} from "./ToeflIbtContext"; // 假设使用 react-audio-player 库
import {FaMicrophone} from 'react-icons/fa';
import {getOS} from "../utils/common";
import {useSavePracticeMutation} from "../../services/cmApi";


function ToeflSpeakingQuestion() {
    const {partIdx} = useParams();
    const {
        answerContent,
        questionContent,
        setAnswerContent,
        volume,
        mockId
    } = React.useContext(ToeflIbtContext);
    const [question, setQuestion] = useState({});
    const [display, setDisplay] = useState(false);
    const [preparationTime, setPreparationTime] = useState(15);
    const [responseTime, setResponseTime] = useState(45);
    const [initialTime, setInitialTime] = useState(preparationTime);
    const [audioPath, setAudioPath] = useState('');
    const history = useHistory();
    const toast = useToast();
    const preparationAudio = 'https://lingoleapstorage1.blob.core.windows.net/sm-audio/begin_preara_after_the_bi.mp3'
    const responseAudio = 'https://lingoleapstorage1.blob.core.windows.net/sm-audio/begin_speaking_after_the_bi.mp3'
    const [started, setStarted] = useState(false);
    const [isResponse, setIsResponse] = useState(false);
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const audioRef = useRef(null);
    const [savePracticeRequest, savePracticeError] = useSavePracticeMutation();

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.volume = volume; // 设置音量
        }
    }, [volume]);

    useEffect(() => {
        setDisplay(false)
        getQuestionInfo();
    }, [questionContent, partIdx]);


    function getQuestionInfo() {
        const taskObj = questionContent?.['speaking']?.tasks[partIdx - 1];
        setQuestion(taskObj);
        if (taskObj?.question_audio) {
            setAudioPath(taskObj.question_audio);
        }
        if (taskObj.gen_type === 'speaking_task1') {
            setPreparationTime(15)
            setResponseTime(45)
            setInitialTime(15)
        } else {
            setPreparationTime(30)
            setResponseTime(60)
            setInitialTime(30)
        }
    }

    function CountdownTimer({initialTime, onFinish, title, titleColor}) {
        const [timeLeft, setTimeLeft] = useState(initialTime);

        useEffect(() => {
            setIsResponse(title === 'RESPONSE TIME')
            if (title === 'RESPONSE TIME') {
                setAudioPath(responseAudio)
            } else {
                setAudioPath(preparationAudio)
            }
            return () => {
                if (mediaRecorder) {
                    mediaRecorder.stop();
                }
            }
        }, []);

        useEffect(() => {
            if (!started) return;
            const timerId = setInterval(() => {
                if (timeLeft > 0) {
                    setTimeLeft(timeLeft - 1);
                } else {
                    clearInterval(timerId);
                    if (mediaRecorder) {
                        mediaRecorder.stop();
                    }
                    setStarted(false)
                    onFinish();
                }
            }, 1000);
            return () => {
                clearInterval(timerId);
            }
        }, [timeLeft, onFinish, started]);

        const formatTime = (seconds) => {
            const minutes = String(Math.floor(seconds / 60)).padStart(2, '0');
            const remainingSeconds = String(seconds % 60).padStart(2, '0');
            return `00:${minutes}:${remainingSeconds}`;
        };


        return (
            <Box borderRadius={"xl"} width={"260px"} border={"1px solid gray"} overflow={"hidden"}>
                <Flex direction={"column"} align={'center'}>
                    <Text fontWeight={"bold"} p={2} bgColor={titleColor} color={"white"} width={"100%"}
                          align={'center'}>{title}</Text>
                    <Flex direction={"row"} p={1} mt={1}>
                        <Icon as={FaMicrophone} mr={2} mt={'2px'}/>
                        <Text fontWeight={"bold"}>{formatTime(timeLeft)}</Text>
                    </Flex>
                </Flex>
            </Box>
        );
    }

    function HandleNext() {
        const parentPath = location.pathname.split("/question/")[0];
        const pathSegments = parentPath.split("/");
        const qidStr = pathSegments.pop(); // 获取最后一个路径段
        let nextUrl = '';
        console.log('parentPath', parentPath)
        console.log('pathSegments', pathSegments)
        console.log('partIdx', partIdx)
        if (qidStr === 'all') {
            if (['1', '2', '3'].includes(partIdx)) {
                nextUrl = `${parentPath}/directions/${Number(partIdx) + 1}`;
            } else if (partIdx === '4') {
                if (parentPath.includes("all_")) {
                    const newParentPath = parentPath.replace("speaking", "writing");
                    nextUrl = `${newParentPath}/directions`;
                } else {
                    nextUrl = `${parentPath}/answer`
                }
            }
        } else {
            nextUrl = `${parentPath}/answer`
        }
        console.log('nextUrl', nextUrl)
        history.replace(nextUrl);
    }


    const onAudioSave = (data) => {
        const gidV = answerContent?.['speaking']?.[partIdx]?.['gid'];
        if (gidV) { // 存在gidV表明音频已保存
            return;
        }
        // 后端保存音频
        let formData = {
            'gen_type': question.gen_type,
            'tpo_number': question.tpo_number,
            'gid': gidV,
            'qid': question.qid,
            'topic': question.question,
            'audio': data,
            'mock_id': mockId
        };
        savePracticeRequest(formData)
            .then(response => {
                if (response.data.status === 'success') {
                    updateAnswerContent(response.data.data.gid, data);
                    console.log("save practice gid: ", response.data.data.gid);
                } else {
                    updateAnswerContent(null, data);
                    console.error("Failed to save practice:", response.data);
                }
            })
            .catch(error => {
                updateAnswerContent(null, data);
                console.error("save practice error", error);
            });
    };

    const updateAnswerContent = (gid, data) => {
        setAnswerContent(prevAnswerContent => {
            const updatedPart = {
                ...(prevAnswerContent[question.section]?.[partIdx] || {}),
                gid: gid,
                answer: data
            };
            return {
                ...prevAnswerContent,
                [question.section]: {
                    ...prevAnswerContent[question.section],
                    [partIdx]: updatedPart
                }
            };
        });
    };

    const handleRecord = () => {
        if (!isResponse) return;
        if ("MediaRecorder" in window) {
            navigator.mediaDevices.getUserMedia({audio: true})
                .then((mediaStream) => {
                    const recorder = new MediaRecorder(mediaStream);
                    setMediaRecorder(recorder);
                    recorder.start();
                    console.log("Recording started")
                    recorder.addEventListener('dataavailable', (e) => {
                        const file = new File([e.data], 'recorded_audio.webm', {type: e.data.type});
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onloadend = () => {
                            onAudioSave(reader.result);
                            recorder.stream.getTracks().forEach((t) => t.stop());
                        };
                    });
                })
                .catch((r) => {
                    const os = getOS();
                    let msg = 'Please allow microphone access';  // Default message
                    if (os === "iOS") {
                        msg = 'Please go to Settings and allow microphone access for this app';  // Adjust the message for iOS
                    }
                    toast({
                        title: 'Unable to access the microphone',
                        description: msg,
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    });
                    console.log("Error in obtaining media stream: ", r.message, os);
                });

        } else {
            alert("The MediaRecorder API is not supported in your browser.");
        }
    };

    return (
        <Box maxWidth="860px" margin="auto" padding="4">
            <VStack spacing={10} align="stretch">
                <audio
                    src={audioPath}
                    autoPlay={true}
                    ref={audioRef}
                    onEnded={() => {
                        if (!display) {
                            setDisplay(true);
                        } else {
                            setStarted(true)
                            if (isResponse) {
                                handleRecord();
                            }
                        }
                    }}
                    style={{display: 'none'}}
                />
                <Text fontSize="lg">{question?.question}</Text>
                {/*<Box height={4}></Box>*/}
                {display && (
                    <Flex direction={"column"}>
                        <Divider borderWidth={1} borderColor={"gray"}/>
                        <Box height={'10'}></Box>
                        <Flex direction={"row"} justify="space-between">
                            <Text>Preparation Time:</Text>
                            <Text>{preparationTime} Seconds</Text>
                        </Flex>
                        <Box height={'4'}></Box>
                        <Flex direction={"row"} justify="space-between">
                            <Text>Record Time:</Text>
                            <Text>{responseTime} Seconds</Text>
                        </Flex>
                        <Box height={'10'}></Box>
                        <Center>
                            {initialTime === preparationTime ?
                                <CountdownTimer initialTime={initialTime} onFinish={() => setInitialTime(responseTime)}
                                                title={"PREPARATION TIME"} titleColor={"purple"}
                                />
                                : <CountdownTimer initialTime={initialTime} onFinish={() => {
                                    HandleNext();
                                }} title={"RESPONSE TIME"} titleColor={"teal"}
                                />}
                        </Center>
                    </Flex>
                )}
            </VStack>
        </Box>
    );
}

export default ToeflSpeakingQuestion;
