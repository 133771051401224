import {Box, Divider, Heading, Text, VStack} from '@chakra-ui/react';
import React, {useEffect, useRef} from 'react';
import {useHistory, useParams} from "react-router-dom";
import {ToeflIbtContext} from "./ToeflIbtContext";

function ToeflSpeakingDirections() {
    const {partIdx} = useParams();
    const {
        volume
    } = React.useContext(ToeflIbtContext);
    const audioRef = useRef(null);
    let history = useHistory();

    let directionAudio = ''
    if (partIdx === undefined) {
        directionAudio = 'https://lingoleapstorage1.blob.core.windows.net/sm-audio/speaking_section_directions.mp3'
    } else if (partIdx === '1') {
        directionAudio = 'https://lingoleapstorage1.blob.core.windows.net/sm-audio/speaking_section_directions1.mp3'
    } else if (partIdx === '2') {
        directionAudio = 'https://lingoleapstorage1.blob.core.windows.net/sm-audio/speaking_section_directions2.mp3'
    } else if (partIdx === '3') {
        directionAudio = 'https://lingoleapstorage1.blob.core.windows.net/sm-audio/speaking_section_directions3.mp3'
    } else if (partIdx === '4') {
        directionAudio = 'https://lingoleapstorage1.blob.core.windows.net/sm-audio/speaking_section_directions4.mp3'
    }

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.volume = volume; // 设置音量
        }
    }, [volume]);

    function getNextUrl() {
        const curPath = location.pathname
        if (partIdx === undefined) {
            return `${curPath}/1`;
        } else if (partIdx === '1') {
            return curPath.replace('/directions', '/question');
        } else if (partIdx === '2' || partIdx === '3') {
            return curPath.replace('/directions', '/text');
        } else if (partIdx === '4') {
            return curPath.replace('/directions', '/audio');
        }
    }

    const handleAudioEnded = () => {
        const nextUrl = getNextUrl();
        history.replace(nextUrl);
    };


    return (
        <Box maxWidth="860px" margin="auto" padding="4">
            <audio
                src={directionAudio}
                autoPlay={true} // 自动播放音频
                ref={audioRef}
                onEnded={() => {
                    handleAudioEnded();
                }}
                style={{display: 'none'}} // 隐藏音频控件
            />
            {partIdx === undefined && (
                <VStack spacing={8} align="stretch">
                    <Heading as="h1" size="lg" textAlign="left" style={{fontWeight: 'normal'}}>
                        Speaking Section Directions
                    </Heading>
                    <Divider borderWidth={1}/>
                    <Text>
                        In this section, you will be able to demonstrate your ability to speak about a variety of
                        topics.
                    </Text>
                    <Text>
                        You will answer four questions by speaking into the microphone.
                    </Text>
                    <Text>
                        For each question, you will have time to prepare before giving your response.
                    </Text>
                    {/*<Text>*/}
                    {/*    In this practice test, you can select <span*/}
                    {/*    style={{fontWeight: 'bold'}}>Stop Recording</span> to stop the recording of your response. You*/}
                    {/*    can also select <span style={{fontWeight: 'bold'}}>Play Back Response</span> to hear your*/}
                    {/*    recording. You will have the opportunity to record your response again by selecting <span*/}
                    {/*    style={{fontWeight: 'bold'}}>Record Again</span>, or you may select <span*/}
                    {/*    style={{fontWeight: 'bold'}}>Confirm Response</span> to keep your response. In questions 2*/}
                    {/*    through 4, you can select <span style={{fontWeight: 'bold'}}>Replay Talk</span> to listen to the*/}
                    {/*    conversation or lecture again.*/}
                    {/*</Text>*/}
                    {/*<Text>*/}
                    {/*    <span style={{fontWeight: 'bold'}}>Stop Recording</span>, <span style={{fontWeight: 'bold'}}>Play Back Response</span>, <span*/}
                    {/*    style={{fontWeight: 'bold'}}>Record Again</span>, <span style={{fontWeight: 'bold'}}>Confirm Response</span>,*/}
                    {/*    and <span style={{fontWeight: 'bold'}}>Replay Talk</span> are not available in the actual test.*/}
                    {/*    Note that using these functions could result in a section score higher than the score you would*/}
                    {/*    receive if you did not use them.*/}
                    {/*</Text>*/}
                    {/*<Text>*/}
                    {/*    (Select <span style={{fontWeight: 'bold'}}>Continue</span> at any time to dismiss these*/}
                    {/*    directions.)*/}
                    {/*</Text>*/}
                </VStack>
            )}
            {partIdx === '1' && (
                <VStack spacing={8} align="stretch">
                    <Heading as="h1" size="lg" textAlign="left" style={{fontWeight: 'normal'}}>
                        Question 1 Directions
                    </Heading>
                    <Divider borderWidth={1}/>
                    <Text>
                        In this question, you will be asked to give your opinion about a familiar topic. After you hear
                        the question, you will have 15 seconds to prepare your response and 45 seconds to speak.
                    </Text>
                </VStack>
            )}
            {partIdx === '2' && (
                <VStack spacing={8} align="stretch">
                    <Heading as="h1" size="lg" textAlign="left" style={{fontWeight: 'normal'}}>
                        Question 2 Directions
                    </Heading>
                    <Divider borderWidth={1}/>
                    <Text>
                        In this question, you will read a short passage about a campus situation, and then listen to a
                        conversation on the same topic. You will then answer a question using information from both the
                        reading passage and the conversation. After the question, you will have 30 seconds to prepare
                        your response and 60 seconds to speak.
                    </Text>
                </VStack>
            )}
            {partIdx === '3' && (
                <VStack spacing={8} align="stretch">
                    <Heading as="h1" size="lg" textAlign="left" style={{fontWeight: 'normal'}}>
                        Question 3 Directions
                    </Heading>
                    <Divider borderWidth={1}/>
                    <Text>
                        In this question, you will read a short passage on an academic subject and then listen to a talk
                        on the same topic. You will then answer a question using information from both the reading
                        passage and the talk. After the question, you will have 30 seconds to prepare your response and
                        60 seconds to speak.
                    </Text>
                </VStack>
            )}
            {partIdx === '4' && (
                <VStack spacing={8} align="stretch">
                    <Heading as="h1" size="lg" textAlign="left" style={{fontWeight: 'normal'}}>
                        Question 4 Directions
                    </Heading>
                    <Divider borderWidth={1}/>
                    <Text>
                        In this question, you will listen to a short lecture. You will then be asked to summarize
                        important information from the lecture. After you hear the question, you will have 20 seconds to
                        prepare your response and 60 seconds to speak.
                    </Text>
                </VStack>
            )}
        </Box>
    );
}

export default ToeflSpeakingDirections;
