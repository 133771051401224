import React, {useEffect, useRef, useState} from 'react';
import {
    Box,
    Checkbox,
    CheckboxGroup,
    Radio,
    RadioGroup,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    VStack
} from '@chakra-ui/react';
import {useParams} from "react-router-dom";
import {ToeflIbtContext} from "./ToeflIbtContext";
import {RxDragHandleDots2} from "react-icons/rx";

function ListeningQuestion({setIsCountdownPaused}) {
    const {partIdx, subIdx} = useParams();
    const {
        questionContent,
        answerContent,
        setAnswerContent,
        volume
    } = React.useContext(ToeflIbtContext);
    const [question, setQuestion] = useState({});
    const [display, setDisplay] = useState(false);
    const [questionAudio, setQuestionAudio] = useState('');
    const [groupType, setGroupType] = useState('Radio');
    const [userAnswerTexts, setUserAnswerTexts] = useState({})
    const audioRef = useRef(null);
    const [hasDragBox, setHasDragBox] = useState(true);
    const tableValues = []

    useEffect(() => {
        setQuestion({})
        setDisplay(false)
        setQuestionAudio('')
        getQuestionInfo();
    }, [questionContent, partIdx, subIdx]);

    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.volume = volume; // 设置音量
        }
    }, [volume]);

    function getQuestionInfo() {
        const taskObj = questionContent?.['listening']?.tasks[partIdx - 1];
        if (taskObj && taskObj?.questions) {
            for (const questionObj of taskObj?.questions) {
                if (questionObj.sub_question_idx.toString() === subIdx) {
                    setQuestion(questionObj);
                    if (questionObj.sub_question_audio && questionObj.sub_question_audio.audio_url) {
                        setQuestionAudio(questionObj.sub_question_audio.audio_url);
                    }
                    checkGroupType(questionObj)
                    return;
                }
            }
        }
    }

    function checkGroupType(questionObj) {
        if (questionObj.sub_question_answer.length === 1) {
            setGroupType('Radio');
        } else if (
            questionObj.sub_question_texts &&
            questionObj.sub_question_texts.some(text => (/drag your/i.test(text) || /drag it/i.test(text)))
        ) {
            setGroupType('Drag');
            initUserAnswerTexts(questionObj);
            setHasDragBox(questionObj.sub_question_texts.some(text => ['1', '2', '3', '4', "5"].includes(text)))
        } else if (
            questionObj.sub_question_texts &&
            questionObj.sub_question_texts.some(text => text.startsWith('Phrases:'))
        ) {
            setGroupType('Table');
        } else {
            setGroupType('Checkbox');
        }
    }

    function initUserAnswerTexts(questionObj) {
        if (questionObj.sub_question_texts) {
            const answerTexts = {};
            const dot_keys = [];
            questionObj.sub_question_texts.forEach((item, idx) => {
                if (['1', '2', '3', '4', "5"].includes(item) && !(
                    idx + 1 < questionObj.sub_question_texts.length
                    && ['1', '2', '3', '4', "5"].includes(question.sub_question_texts[idx + 1]))) {
                    const key = '●_' + idx;
                    answerTexts[key] = '';
                    dot_keys.push(key)
                }
            });
            if (dot_keys.length === 0) { // 没有拖拽框位置,则根据答案选项展示拖拽框
                questionObj.sub_question_answer.split('').forEach((item, idx) => {
                    const key = '●_' + idx;
                    answerTexts[key] = '';
                    dot_keys.push(key)
                });
            }
            Object.keys(questionObj.sub_question_options).map((item, idx) => {
                answerTexts[item] = questionObj.sub_question_options[item];
            });
            const answer = answerContent?.listening?.[subIdx]
            if (answer) {
                for (let idx = 0; idx < answer.length; idx++) {
                    const item = answer[idx];
                    if (["A", "B", "C", "D", "E", "F", "G"].includes(item)) {
                        const key = dot_keys[idx];
                        if (key && answerTexts[item]) {
                            answerTexts[key] = answerTexts[item];
                            answerTexts[item] = '';
                        }
                    }
                }
            }
            console.log('answerTexts', answerTexts)
            setUserAnswerTexts(answerTexts);
        }
    }


    const handleDragStart = (e, id) => {
        e.dataTransfer.setData('text/plain', id);
    };


    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e, targetId) => {
        e.preventDefault();
        const sourceID = e.dataTransfer.getData('text');
        console.log(sourceID, targetId)
        const sourceText = userAnswerTexts[sourceID];
        // 移动到答案框则复原
        if (!targetId.startsWith("●")) {
            targetId = sourceText[0]
        }
        const targetText = userAnswerTexts[targetId];
        if (targetText !== '' || sourceText === '') {
            return;
        }
        const newUserAnswerTexts = {...userAnswerTexts};
        newUserAnswerTexts[targetId] = sourceText;
        newUserAnswerTexts[sourceID] = '';
        setUserAnswerTexts(newUserAnswerTexts);
        handleOptionChange(newUserAnswerTexts);
    };

    const handleOptionChange = (newUserAnswerTexts) => {
        let values = [];
        newUserAnswerTexts && Object.entries(newUserAnswerTexts).map(([key, option]) => {
            if (key.startsWith("●")) {
                if (option !== '') {
                    values.push(option[0])
                } else {
                    values.push('')
                }
            }
        })
        console.log('values', values)
        setAnswerContent(prevAnswerContent => ({
            ...prevAnswerContent,
            listening: {
                ...prevAnswerContent.listening,
                [subIdx]: values
            }
        }));
    };

    const handleTableRadio = (phrases, phraseIdx, optionV) => {
        let answerList = answerContent?.listening?.[subIdx] || [];
        if (answerList.length === 0) {
            answerList = Array(phrases.length).fill('#');
        }
        answerList[phraseIdx] = optionV;
        setAnswerContent(prevAnswerContent => ({
            ...prevAnswerContent,
            listening: {
                ...prevAnswerContent.listening,
                [subIdx]: answerList
            }
        }));
    };


    const renderOptions = () => {
        const handleOptionChange = (value) => {
            if (Array.isArray(value)) {
                value = value.sort();
            }
            console.log('value', value)
            setAnswerContent(prevAnswerContent => ({
                ...prevAnswerContent,
                listening: {
                    ...prevAnswerContent.listening,
                    [subIdx]: value
                }
            }));
        };

        if (groupType === 'Radio') {
            return (
                <RadioGroup value={answerContent?.listening?.[subIdx] || ''} onChange={handleOptionChange}>
                    <VStack align="start" spacing={2}>
                        {question.sub_question_options && Object.entries(question.sub_question_options).map(([key, option]) => (
                            <Radio key={key} value={key}>{option}</Radio>
                        ))}
                    </VStack>
                </RadioGroup>
            );
        } else if (groupType === 'Checkbox') {
            return (
                <>
                    <Box
                        bg="teal.100"
                        borderRadius="xl"
                        padding="2"
                        pl="4"
                        boxShadow="md"
                        textAlign="left"
                        fontSize={"md"}
                    >
                        Select {question.sub_question_answer.length} answers.
                    </Box>
                    <CheckboxGroup value={answerContent?.listening?.[subIdx] || []} onChange={handleOptionChange}>
                        <VStack align="start" spacing={2}>
                            {question.sub_question_options && Object.entries(question.sub_question_options).map(([key, option]) => (
                                <Checkbox key={key} value={key}>
                                    {option}
                                </Checkbox>
                            ))}
                        </VStack>
                    </CheckboxGroup>
                </>
            );
        } else if (groupType === 'Drag') {
            return (
                <VStack alignItems={"center"}
                        w={"100%"}
                        h={"100%"}
                        p={4}
                        spacing={4}
                >
                    {question?.sub_question_texts?.map((item, idx) => {
                        if (idx === 0) {
                            return <></>
                        } else if (item.startsWith('Drag your')) {
                            return (
                                <Box
                                    bg="teal.100"
                                    borderRadius="xl"
                                    padding="2"
                                    pl="4"
                                    boxShadow="md"
                                    textAlign="left"
                                    fontSize={"md"}
                                >
                                    {item}
                                </Box>
                            );
                        } else if (['1', '2', '3', '4', "5"].includes(item)) {
                            const key = `●_${idx}`;
                            if (idx + 1 < question.sub_question_texts.length
                                && !['1', '2', '3', '4', "5"].includes(question.sub_question_texts[idx + 1])) {
                                return null;
                            } else {
                                return (<Box
                                    key={key}
                                    minWidth={'600px'}
                                    w={"600px"}
                                    h={"8vh"}
                                    overflowY="auto"
                                    border="1px"
                                    // borderColor={isDropped ? 'green.500' : 'black.700'}
                                    p={2}
                                    borderRadius="16"
                                    onDragOver={handleDragOver}
                                    onDrop={(e) => handleDrop(e, key)}
                                    draggable={subIdx !== 0}
                                    onDragStart={(e) => handleDragStart(e, key)}
                                    cursor="move"
                                    bg={(userAnswerTexts[key] === '') ? "" : "teal.100"}
                                    color={(userAnswerTexts[key] === '') ? "black.100" : "white.100"}
                                    display="flex"
                                    alignItems="center"
                                >
                                    {userAnswerTexts[key] !== '' && (
                                        <RxDragHandleDots2 size={40}/>
                                    )}
                                    <Text flex={1}>
                                        {userAnswerTexts[key]}
                                    </Text>
                                </Box>)
                            }
                        } else if (!['A', 'B', 'C', 'D', 'E', 'F'].some(text => item.startsWith(text + '.'))) {
                            return (
                                <Box minWidth={'50%'} display='flex' alignItems='center' justifyContent='center'>
                                    {item}
                                </Box>
                            );
                        }
                    })}
                    {!hasDragBox && (
                        question.sub_question_answer.split('')?.map((item, idx) => {
                            const key = `●_${idx}`;
                            return (<Box
                                key={key}
                                minWidth={'600px'}
                                w={"600px"}
                                h={"8vh"}
                                overflowY="auto"
                                border="1px"
                                // borderColor={isDropped ? 'green.500' : 'black.700'}
                                p={2}
                                borderRadius="16"
                                onDragOver={handleDragOver}
                                onDrop={(e) => handleDrop(e, key)}
                                draggable={subIdx !== 0}
                                onDragStart={(e) => handleDragStart(e, key)}
                                cursor="move"
                                bg={(userAnswerTexts[key] === '') ? "" : "teal.100"}
                                color={(userAnswerTexts[key] === '') ? "black.100" : "white.100"}
                                display="flex"
                                alignItems="center"
                            >
                                {userAnswerTexts[key] !== '' && (
                                    <RxDragHandleDots2 size={40}/>
                                )}
                                <Text flex={1}>
                                    {userAnswerTexts[key]}
                                </Text>
                            </Box>)
                        })
                    )}
                    <Box minWidth={'50%'} display='flex' alignItems='center' justifyContent='center'>
                        Answer Choices
                    </Box>
                    {question.sub_question_options && (
                        <VStack alignItems={'start'}>
                            {Object.keys(question.sub_question_options).map((item, idx) => {
                                return (
                                    <Box
                                        key={item}
                                        overflowY="auto"
                                        minWidth={'600px'}
                                        w={"600px"}
                                        h={"8vh"}
                                        border="1px"
                                        borderColor="black.700"
                                        borderRadius="16"
                                        p={2}
                                        onDragOver={handleDragOver}
                                        onDrop={(e) => handleDrop(e, item)}
                                        draggable={subIdx !== 0}
                                        onDragStart={(e) => handleDragStart(e, item)}
                                        cursor="move"
                                        display="flex"
                                        alignItems="center"
                                    >
                                        {userAnswerTexts[item] !== '' && (
                                            <RxDragHandleDots2 size={40}/>
                                        )}
                                        <Text flex={1}>
                                            {userAnswerTexts[item]}
                                        </Text>
                                    </Box>
                                );
                            })}
                        </VStack>
                    )}
                </VStack>
            );
        } else if (groupType === 'Table') {
            return (<>
                <VStack alignItems={"start"}
                        w={"100%"}
                        h={"100%"}
                        p={4}
                        spacing={4}
                >
                    {question?.sub_question_texts?.map((item, idx) => {
                        if (item.startsWith('Click in')) {
                            return (
                                <Box
                                    bg="teal.100"
                                    borderRadius="xl"
                                    padding="2"
                                    pl="4"
                                    boxShadow="md"
                                    textAlign="left"
                                    fontSize={"md"}
                                >
                                    {item}
                                </Box>
                            );
                        } else if (item.startsWith('Phrases:')) {
                            const phrases = item.split(':')[1].trim().split(';');
                            const titles = ['Phrases'].concat(Object.values(question?.sub_question_options || {}));
                            return (
                                <Box borderTop="1px"
                                     borderLeft="1px"
                                     borderRight="1px"
                                     borderRadius="md"
                                     borderColor="gray.200"
                                     overflow="hidden">
                                    <Table variant="simple" size="sm">
                                        <Thead>
                                            <Tr>
                                                {titles.map((title, idx) => (
                                                    <Th key={idx} textAlign={'center'} bg={"gray.300"} borderRight="1px"
                                                        borderRightColor={"gray.700"}
                                                    >{title}</Th>
                                                ))}
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {phrases.map((phrase, phraseIdx) => {
                                                return (
                                                    <Tr key={phraseIdx}>
                                                        <Td borderRight="1px"
                                                            borderRightColor={"gray.300"}>{phrase}</Td>
                                                        {Object.keys(question?.sub_question_options || {}).map((optionKey, optionIdx) => (
                                                            <Td key={`${phraseIdx}-${optionIdx}`}
                                                                textAlign={'center'}
                                                                borderRight="1px"
                                                                borderRightColor={"gray.300"}>
                                                                <Radio
                                                                    key={`${phraseIdx}-${optionIdx}`}
                                                                    value={optionKey[0]}
                                                                    isChecked={answerContent?.listening?.[subIdx]?.[phraseIdx] === optionKey[0]}
                                                                    onChange={() => {
                                                                        handleTableRadio(phrases, phraseIdx, optionKey[0])
                                                                    }}
                                                                />
                                                            </Td>
                                                        ))}
                                                    </Tr>)

                                            })}
                                        </Tbody>
                                    </Table>
                                </Box>
                            );
                        } else {
                            return (<></>);
                        }
                    })}
                </VStack>
            </>)
        }
    }

    return (
        <Box maxWidth="860px" margin="auto" padding="4">
            <VStack spacing={4} align="stretch">
                <audio
                    src={questionAudio}
                    autoPlay={true} // 自动播放音频
                    ref={audioRef}
                    onEnded={() => {
                        setTimeout(() => {
                            setDisplay(true);
                            setIsCountdownPaused(false)
                        }, 500);
                    }}
                    style={{display: 'none'}} // 隐藏音频控件
                />
                <Text fontSize="lg" fontWeight="bold" mb={2}>{question?.sub_question_texts?.[0]}</Text>
                {display && (renderOptions())}
            </VStack>
        </Box>
    );
}

export default ListeningQuestion;
