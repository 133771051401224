import {
    Button,
    Center,
    Divider,
    Flex,
    FormControl,
    FormLabel,
    SimpleGrid,
    Switch,
    Text,
    useBreakpointValue,
    useDisclosure
} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next';
import {useGetBalanceQuery, useGetQuestionListQuery} from "../services/cmApi";
import React, {useEffect, useState} from "react";
import TaskFilterRow from "./TaskFilterRow";
import axios from "axios";
import authHeader from "../services/auth-header";
import OutOfCreditsAlert from "./Dialogs/OutOfCreditsAlert";
import {QuestionCard} from "./QuestionCard";
import {PracticeTypeEnum} from "./toeflIbt/ToeflIbtContext";

const API_URL = process.env.REACT_APP_CM_BACKEND_URL ? process.env.REACT_APP_CM_BACKEND_URL : window.location.origin;


const TpoOptions = {
    toefl: {
        reading: ['all', '66-75', '56-65', '46-55', '36-45', '26-35', '16-25', '6-15', '1-5'],
        listening: ['all', '66-75', '56-65', '46-55', '36-45', '26-35', '16-25', '6-15', '1-5'],
        speaking: ['all', '66-75', '56-65', '46-55', '36-45', '26-35', '16-25', '6-15', '1-5'],
        writing: ['all', '66-75', '56-65', '46-55', '36-45', '26-35', '16-25', '6-15', '1-5'],
        full_mock_test: ['all', '66-75', '56-65', '46-55', '36-45', '26-35', '16-25', '6-15', '1-5']
    }
}

const YearOptions = {
    toefl: {
        reading: ['all', '2024', '2023', '2022', '2021', '2020', '2019', '2018', '2017', '2016', 'unknown'],
        listening: ['all', '2024', '2023', '2022', '2021', '2020', '2019', '2018', '2017', '2016', 'unknown'],
        speaking: ['all', '2024', '2023', '2022', '2021', '2020', '2019', '2018', '2017', '2016', 'unknown'],
        writing: ['all', '2024', '2023', '2022', '2021', '2020', '2019', '2018', '2017', '2016', 'unknown'],
        full_mock_test: ['all', '2024', '2023', '2022', '2021', '2020', '2019', '2018', '2017', '2016', 'unknown']
    }
}

const TASK_OPTIONS = {
    toefl: {
        reading: ['all', 'reading_task1', 'reading_task2', 'reading_task3', 'reading_task4'],
        listening: ['all', 'listening_task1', 'listening_task2', 'listening_task3', 'listening_task4',
            'listening_task5','listening_task6', 'listening_task7'],
        speaking: ['all', 'speaking_task1', 'speaking_task2', 'speaking_task3', 'speaking_task4'],
        writing: ['all', 'writing_task1', 'writing_task3'],
        full_mock_test: ['all', 'reading_task1'] // 全科模考只是获取一个起始task信息即可
    },
    ielts: {
        speaking: ['all', 'ispeaking_part1', 'ispeaking_part2_3'],
        writing: ['iwriting_task2']
    }
};


export default function QuestionListPanel(props) {
    let balance = useGetBalanceQuery({});
    var {test, section, questionSet, tags, hideTags, ...other} = props;
    const columnsValue = useBreakpointValue({base: 1, md: 2, xl: 2});
    const {t, i18n} = useTranslation();
    const storedFilters = loadFiltersFromLocalStorage();
    const tpoOptions = TpoOptions[test]?.[section] || ['all'];
    const [selectedTpoOptions, setSelectedTpoOptions] = useState(storedFilters?.selectedTpoOptions || tpoOptions[0]);
    const yearOptions = YearOptions[test]?.[section] || ['all'];
    const [selectedYearOptions, setSelectedYearOptions] = useState(storedFilters?.selectedYearOptions || yearOptions[0]);
    const task_options = TASK_OPTIONS[test]?.[section];
    const [selectedGenType, setSelectedGenType] = useState(storedFilters?.selectedGenType || task_options[0]);
    const [completionStatus, setCompletionStatus] = useState(storedFilters?.completionStatus || 'all');
    const [isFullSet, setIsFullSet] = useState(storedFilters?.isFullSet || section === PracticeTypeEnum.FULL_MOCK_TEST || false);
    let params = {
        test: test,
        section: section,
        question_set: questionSet,
        is_full_set: isFullSet,
        gen_types: task_options,
    };
    let questionList = []
    questionList = useGetQuestionListQuery(params);
    const [currentPage, setCurrentPage] = useState(Number(storedFilters?.currentPage || 1));
    const itemsPerPage = useBreakpointValue({base: 4, md: 10, xl: 10});
    const [filterQuestionList, setFilterQuestionList] = useState([]);
    const [currentPageQuestionList, setCurrentPageQuestionList] = useState([]);

    const {
        isOpen: isOutOfCreditsAlertOpen,
        onOpen: onOutOfCreditsAlertOpen,
        onClose: onOutOfCreditsAlertClose
    } = useDisclosure()

    useEffect(() => {
        if (questionList.status !== 'fulfilled') {
            return;
        }
        const shownQuestionList = [];
        const selectedTpoNumbers = selectedTpoOptions === 'all' ? [] : parseRangeToNumbers(selectedTpoOptions);
        questionList.data.data.forEach(item => {
            if (selectedTpoNumbers.length > 0 && !selectedTpoNumbers.includes(Number(item.tpo_number))) {
                return;
            }
            if (selectedYearOptions !== 'all') {
                if (selectedYearOptions === 'unknown' && item.question_year !== '') {
                    return;
                }
                if (selectedYearOptions !== 'unknown' && item.question_year !== Number(selectedYearOptions)) {
                    return;
                }
            }
            if (selectedGenType !== 'all' && item.gen_type !== selectedGenType) {
                return;
            }
            if ((completionStatus === 'completed' && !item.completed) || (completionStatus === 'not_completed' && item.completed)) {
                return;
            }
            shownQuestionList.push(item)
        });
        saveFiltersToLocalStorage();
        setFilterQuestionList(shownQuestionList);
    }, [questionList, selectedTpoOptions, selectedYearOptions, selectedGenType, isFullSet, completionStatus]);


    useEffect(() => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        let PageQuestionList = filterQuestionList.slice(startIndex, endIndex);
        if (!PageQuestionList?.length || completionStatus !== 'all') { // 页面数据为空时或completionStatus不为all时，不重新获取数据状态
            setCurrentPageQuestionList(PageQuestionList)
            return;
        }
        try {
            let qids = [];
            PageQuestionList.forEach(item => {
                if (Array.isArray(item.qids)) {
                    qids = qids.concat(item.qids);
                } else {
                    qids.push(item.qid);
                }
            })
            const param = {
                qids: qids,
                section: section
            };
            let headers = authHeader();
            axios.post(API_URL + '/api/get_question_list_status', param, {headers: headers})
                .then(response => {
                    if (response.status === 200) {
                        PageQuestionList = PageQuestionList.map(item => {
                            const status = isFullSet
                                ? item.qids.every(qid => response.data.data[qid])
                                : response.data.data[item.qid];
                            return {
                                ...item,
                                completed: status
                            };
                        });
                        setCurrentPageQuestionList(PageQuestionList)
                    }
                }).catch(error => {
                console.error('There was an error!', error);
            });
        } catch (error) {
            console.error('Error occurred while obtaining the content of the problem:', error);
        }

    }, [filterQuestionList, currentPage, completionStatus]);


    function saveFiltersToLocalStorage() {
        const questionListFilters = {
            "section": section,
            "questionSet": questionSet,
            "selectedTpoOptions": selectedTpoOptions,
            "selectedYearOptions": selectedYearOptions,
            "selectedGenType": selectedGenType,
            "completionStatus": completionStatus,
            "isFullSet": isFullSet,
            "currentPage": currentPage
        };
        const filtersString = JSON.stringify(questionListFilters);
        localStorage.setItem('questionListFilters', filtersString);
    }

    function loadFiltersFromLocalStorage() {
        const questionListFilters = localStorage.getItem('questionListFilters');
        if (questionListFilters) {
            const storedFilters = JSON.parse(questionListFilters);
            if (storedFilters.section === section && storedFilters.questionSet === questionSet) {
                return storedFilters
            }
        }
    }


    function parseRangeToNumbers(rangeString) {
        const [start, end] = rangeString.split('-').map(Number);
        const numbers = [];
        for (let i = start; i <= end; i++) {
            numbers.push(i);
        }
        return numbers;
    }


    const getQuestionListBody = () => {
        if (!currentPageQuestionList?.length) {
            return null;
        }
        saveFiltersToLocalStorage();

        function getTitle(item) {
            if (section === PracticeTypeEnum.FULL_MOCK_TEST) {
                return `${item.title}`;
            } else if (isFullSet) {
                return `${item.title} ${t(item.gen_type).slice(0, -1)}`;
            } else {
                return `${item.title} ${t(item.gen_type)}`;
            }
        }

        return <>
            <SimpleGrid columns={columnsValue} spacing="10px" gap="10px" w={'100%'}>
                {currentPageQuestionList.map((item, index) => {
                    return (
                        <Center key={item.qid}> {/* 使用 item.qid 作为 key */}
                            <QuestionCard
                                genType={item.gen_type}
                                title={getTitle(item)}
                                summary={typeof item.summary === 'string' ? item.summary : item.summary.join('')}
                                tags={hideTags ? [] : item.tags}
                                qid={item.qid}
                                completed={item.completed}
                                isFree={item.is_free}
                                balanceValue={(balance && balance.data && balance.data["balance"]) || 0}
                                onAlertOpen={onOutOfCreditsAlertOpen}
                                isFullSet={isFullSet}
                                tpoNumber={item.tpo_number}
                                orderIndex={item.order_index}
                                questionSet={item.question_set}
                                section={section}
                            />
                        </Center>
                    );
                })}
            </SimpleGrid>
        </>
    }

    const totalPages = filterQuestionList?.length ? Math.ceil(filterQuestionList?.length / itemsPerPage) : 0;

    const paginationControls = (
        <Flex mt={4} justifyContent="center">
            <Button
                onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                disabled={currentPage === 1}
            >
                ←
            </Button>
            <Center>
                <Text mx={4}>
                    {currentPage}/ {totalPages}
                </Text>
            </Center>
            <Button
                onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                disabled={currentPage === totalPages}
            >
                →
            </Button>
        </Flex>
    );

    return <>
        <Flex mx="40px" flexDirection="column" gap="5px">
            {(test === 'toefl') && (questionSet === 'tpo' ? (
                    tpoOptions && <TaskFilterRow
                        rowName={t("number") + ":"}
                        options={tpoOptions}
                        names={tpoOptions.map(option => t(option))}
                        selectedOption={selectedTpoOptions}
                        onChange={(value) => {
                            setSelectedTpoOptions(value);
                            // dispatch(updatePart(mapIeltsPartIndex(value, section)));
                            setCurrentPage(1);
                        }}
                    />
                ) :
                (yearOptions && <TaskFilterRow
                    rowName={t('year') + ":"}
                    options={yearOptions}
                    names={yearOptions.map(option => t(option))}
                    selectedOption={selectedYearOptions}
                    onChange={(value) => {
                        setSelectedYearOptions(value);
                        // dispatch(updatePart(mapIeltsPartIndex(value, section)));
                        setCurrentPage(1);
                    }}
                />))
            }
            {(section === PracticeTypeEnum.FULL_MOCK_TEST) ? null : (
                <TaskFilterRow
                    rowName={t('task') + ":"}
                    options={task_options}
                    names={task_options.map((x) => t(x))}
                    selectedOption={selectedGenType}
                    onChange={(value) => {
                        setSelectedGenType(value)
                        // dispatch(updatePart(mapIeltsPartIndex(value, section)));
                        setCurrentPage(1);
                    }}
                > </TaskFilterRow>
            )}
            <Flex direction={"row"} justify="space-between">
                <TaskFilterRow
                    rowName={t('completion_status') + ":"}
                    options={['all', 'completed', 'not_completed']}
                    selectedOption={completionStatus}
                    names={[t("all"), t("completed"), t("not_completed")]}
                    onChange={(value) => {
                        setCompletionStatus(value)
                        setCurrentPage(1);
                    }}
                > </TaskFilterRow>
                {['reading', 'listening'].includes(section) && (
                    <Flex>
                        <FormControl display='flex' alignItems='center'>
                            <FormLabel htmlFor='full_set' mb='0' size={'sm'} color={"black"}>
                                {t("full_exercise_set")}
                            </FormLabel>
                            <Switch id='full_set' size='md' isChecked={isFullSet} colorScheme={"green"}
                                    onChange={(e) => {
                                        setIsFullSet(!isFullSet)
                                    }}/>
                        </FormControl>
                    </Flex>
                )}
            </Flex>
        </Flex>

        <Divider my="26px" mx="20px"/>
        <Flex mt={5}>
            {getQuestionListBody()}
        </Flex>
        <OutOfCreditsAlert isOpen={isOutOfCreditsAlertOpen} onClose={onOutOfCreditsAlertClose}/>
        {paginationControls}
    </>
}
