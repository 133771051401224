import {Box, Image, Progress, Text,} from '@chakra-ui/react';
import React, {useEffect, useRef, useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import {ToeflIbtContext} from "./ToeflIbtContext";

function ToeflAudio() {
    let history = useHistory();
    const {partIdx} = useParams();
    const {questionContent} = React.useContext(ToeflIbtContext);
    const [audioPath, setAudioPath] = React.useState(null);
    const [imgPath, setImgPath] = React.useState(null);
    const [isDisplay, setIsDisplay] = React.useState(false);
    const [progress, setProgress] = useState(0); // 添加进度状态
    const audioRef = useRef(null); // 创建 ref
    const {volume} = React.useContext(ToeflIbtContext); // 获取音量
    const section = getSection()


    useEffect(() => {
        if (audioRef.current) {
            audioRef.current.volume = volume; // 设置音量
        }
    }, [volume]);

    useEffect(() => {
        getAudioAndImage();
    }, [questionContent, partIdx]);

    function getSection() {
        const sectionStr = location.pathname.split('/')[3]
        return ['reading', 'listening', 'writing', 'speaking'].filter(
            (section) => sectionStr.includes(section)
        );
    }


    function getAudioAndImage() {
        const task = questionContent?.[section]?.tasks[partIdx - 1]
        setAudioPath(task?.listening_audio)
        setImgPath(task?.listening_img)
    }

    const handleTimeUpdate = () => {
        if (audioRef.current) {
            const currentTime = audioRef.current.currentTime;
            const duration = audioRef.current.duration;
            const percent = (currentTime / duration) * 100;
            setProgress(percent); // 更新进度
        }
    };

    const handleAudioEnded = () => {
        const nextUrl = getNextUrl();
        console.log("nextUrl", nextUrl)
        history.replace(nextUrl);
    };

    function getNextUrl() {
        const parentPath = location.pathname.split("/audio")[0]
        if (parentPath.includes("listening")) {
            const nextSubIdx = questionContent?.[section]?.tasks[partIdx - 1].questions[0].sub_question_idx
            return `${parentPath}/question/${partIdx}/${nextSubIdx}`
        } else if (parentPath.includes("speaking")) {
            return `${parentPath}/question/${partIdx}/`
        } else if (parentPath.includes("writing")) {
            if (!isDisplay) {
                setIsDisplay(true) // 显示文字
                setAudioPath('https://lingoleapstorage1.blob.core.windows.net/sm-audio/help_you_answer.mp3')
            } else {
                return `${parentPath}/question/${partIdx}/`
            }
        }
    }


    return (
        <Box maxWidth="860px" margin="auto" padding="4">
            <audio
                src={audioPath}
                autoPlay={true} // 自动播放音频
                ref={audioRef} // 使用 ref 来控制音频
                onTimeUpdate={handleTimeUpdate} // 监听时间更新
                onEnded={handleAudioEnded}
                style={{display: 'none'}} // 隐藏音频控件
            />
            {
                isDisplay ? (
                    <>
                        <Box display="flex" justifyContent="center" mt={12}>
                            <Text>Now get ready to answer the question. \n You may use your notes to help you
                                answer. </Text>
                        </Box>
                    </>
                ) : (
                    <>
                        <Box>
                            {imgPath ? <Image
                                    src={imgPath}
                                    alt="Listening question image"
                                    width="100%"
                                    objectFit="cover"
                                /> :
                                <Box h={'300px'}></Box>
                            }
                        </Box>
                        <Box mt={4}>
                            <Box display="flex" justifyContent="center" mt={12}> {/* 添加容器并居中 */}
                                <Progress
                                    value={progress} // 使用进度状态
                                    size="lg" // 设置进度条大小
                                    colorScheme="teal" // 设置进度条颜色为绿色
                                    border="1px solid black" // 边框颜色
                                    width="80%" // 设置宽度
                                    borderRadius={"xl"}
                                />
                            </Box>
                        </Box>
                    </>
                )
            }
        </Box>
    );
}

export default ToeflAudio;
