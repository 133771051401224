import {CircularProgress, CircularProgressLabel, Spinner} from "@chakra-ui/react";
import "assets/css/Markdown.css";
import {useEffect, useState} from "react";
import ReactAudioPlayer from "react-audio-player";
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from "react-redux";
import "../index.css";
import {selectGrading} from "../redux/shareSlice";
import {selectToeflData, updateToelfGid} from "../redux/toeflSlice";
import {useGetGradingQuery, useUpdateSharingStatusMutation} from "../services/cmApi";
import ConversationTable from "./ConversationTable";
import ResponseFeedBack from "./ResponseFeedBack";
import {ShareResultButton} from "./ShareResultButton";
import './styles.css';

const initialContent = `
  <div id="content-1">Testing, testing.</div>
  <div id="content-2"><mark data-id="correction-1">Highlighted part that has a correction.</mark></div>
  <div id="content-3">Another paragraph.</div>
`;

const corrections = [
    {
        id: 'correction-1',
        original: 'Testing, testing.',
        revised: 'Testing, testing',
        reason: 'Removed the period at the end as it is not necessary for a repeated phrase.',
        type: 'punctuation',
    }
];

export function CardObj({color, desc, score, heading, total_score = 5}) {
    // Calculate percentage for CircularProgress (0-100)
    const progressValue = (score / total_score) * 100;
    
    return (
        <div className="rounded-xl bg-gray-100 border-2 border-solid-[#000000] p-3 min-h-96">
            <div className="flex items-center justify-between">
                <h2 className="text-2xl font-extrabold">
                    {heading}
                </h2>
                <div className="relative flex items-center justify-center w-20 h-20">
                    <CircularProgress value={progressValue} color={color} size="80px">
                        <CircularProgressLabel
                            fill={`${color}.500`}
                            color={`${color}.500`}
                            fontWeight="bold"
                            fontSize="3xl"
                        >
                            <div className={`text-${color}-200`}>{score}</div>
                        </CircularProgressLabel>
                    </CircularProgress>
                </div>
            </div>
            <div className="mt-4">
                <div>{desc}</div>
            </div>
        </div>
    );
}


export function ViewAppResultPanel(props) {
    const {t, i18n} = useTranslation();

    const [linkCopied, setLinkCopied] = useState(false);
    const grading = useSelector(selectGrading);
    const toeflData = useSelector(selectToeflData);
    const gid = props.gid || toeflData.gid;
    const gradingData = props.gradingData || null;
    const dispatch = useDispatch();

    // State for revised essay, keywords, and loading state
    const [revisedEssay, setRevisedEssay] = useState("");
    const [keywords, setKeywords] = useState([]);
    const [additonalExamples, setAdditionalExamples] = useState([]);
    const [mindmap, setMindmap] = useState({});
    const [result, setResult] = useState([]);
    const [loading, setLoading] = useState(true);  // Added loading state
    const [content, setContent] = useState("");
    const [corrections, setCorrections] = useState([]);
    const [subscoreData, setSubscoreData] = useState([]);
    const [correctionCounts, setCorrectionCounts] = useState([]);
    const [totalScore, setTotalScore] = useState(0);
    const [exampleAudio, setExampleAudio] = useState(null);
    const [selectedCorrection, setSelectedCorrection] = useState(null);
    const [nWords, setNWords] = useState(0);
    const [nSentences, setNSentences] = useState(0);
    const [estimatedScore, setEstimatedScore] = useState(0);

    // const editor = new Editor({
    //     content: initialContent,
    //     extensions: [
    //         Doc,
    //         Paragraph,
    //         Text,
    //         Highlight
    //     ],
    //     editorProps: {
    //         attributes: {
    //             class: 'tiptap'
    //         }
    //     }
    // });

    // const editorRef = useRef(editor);
    useEffect(() => {
        const style = document.createElement('style');
        style.innerHTML = `.custom-scrollbar::-webkit-scrollbar {
                width: 12px; /* 滚动条宽度 */
            }
            .custom-scrollbar::-webkit-scrollbar-track {
                background: #f1f1f1; /* 滚动条轨道背景 */
                border-radius: 6px; /* 滑块圆角 */
            }
            .custom-scrollbar::-webkit-scrollbar-thumb {
                background-color: #888; /* 滑块颜色 */
                border-radius: 6px; /* 滑块圆角 */
                border: 3px solid #f1f1f1; /* 滑块边框 */
            }
            .custom-scrollbar::-webkit-scrollbar-thumb:hover {
                background-color: #555; /* 滑块悬停颜色 */
            }
            .custom-scrollbar::-webkit-scrollbar-horizontal {
                display: none; /* 隐藏水平滚动条 */
            }
        `;
        document.head.appendChild(style);
        return () => {
            document.head.removeChild(style);
        };
    }, []);

    const handleHighlightClick = (correction) => {
        // if (correction) {
        //     alert(`Correction: ${correction.reason}\nOriginal: ${correction.original}\nRevised: ${correction.revised}`);
        // }
        setSelectedCorrection(correction);
    };

    const handleContentClick = (event) => {
        if (event.target.classList.contains('correction')) {
            const correctionIndex = event.target.getAttribute('data-correction-index');
            const correction = corrections[correctionIndex];
            handleHighlightClick(correction);
        }
    };
    const applyCorrections = (content, corrections) => {
        const escapeRegExp = (string) => {
            return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
        };

        let updatedContent = content;

        corrections.forEach((correction, index) => {
            // Skip if original text is null/undefined
            if (!correction.original) return;
            
            const regex = new RegExp(escapeRegExp(correction.original), 'g');
            updatedContent = updatedContent.replace(regex, match => {
                return `<span 
                className="correction" 
                style="text-decoration: underline; color: red; font-weight: bold; cursor: pointer;" 
                data-correction-index="${index}"
            >${match}</span>`;
            });
        });

        return updatedContent;
    };

    // useEffect(() => {
    //     if (editorRef.current) {
    //         editorRef.current.setOptions({
    //             editor: editorRef.current,
    //             onClick: handleHighlightClick,
    //         });
    //     }
    // }, [editor]);
    //
    // if (!editor) {
    //     return null;
    // }

    useEffect(() => {
        dispatch(updateToelfGid(gid));
    }, [gid]);

    const [updateSharingStatusRequest, isUpdateSharingStatusError] = useUpdateSharingStatusMutation();
    let grading_result = gradingData || useGetGradingQuery({gid: gid});
    const isOwner = (grading_result.status === 'fulfilled' && grading_result.data.data['is_owner']);

    const formatCategoryName = (category) => {
        let category_str = ''
        if (typeof category === 'string') {
            category_str = category
        } else if (typeof category === 'object') {
            category_str = category[0]
        }
        if (category_str === '') {
            return category_str
        }
        return category_str
            .replace(/_/g, ' ') // Replace underscores with spaces
            .replace(/\b\w/g, char => char.toUpperCase()); // Capitalize first letter of each word
    };
    const getRandomColor = () => {
        const colors = ['#F17EB8', '#16BDCA', '#3F83F8']; // Red, Green, Blue
        const randomIndex = Math.floor(Math.random() * colors.length);
        return colors[randomIndex];
    };

    useEffect(() => {
        console.log('grading_result', grading_result)
        if (grading_result?.data?.data) {
            try {
                // Assuming grading_result.data.data.result is a JSON string of an array
                const jsonStrings = grading_result.data.data.result.split('\n').filter(Boolean);
                const parsedData = jsonStrings.map(jsonStr => {
                    try {
                        return JSON.parse(jsonStr);
                    } catch (parseError) {
                        console.error('Error parsing JSON string:', parseError);
                        return null;
                    }
                }).filter(Boolean);

                // Extract the relevant content from the parsed data
                console.log('parsedData', parsedData)
                const content = grading_result.data.data.gen_type.startsWith('speaking') ?
                    parsedData.find(item => item.content)?.content || "" : grading_result.data.data.content;
                const revisedAnswer = parsedData.find(item => item.revised_answer)?.revised_answer || "";
                const keywords = parsedData.find(item => item.keywords)?.keywords || [];
                const additionalExamples = parsedData.find(item => item.additional_examples)?.additional_examples || [];
                const mindmap = parsedData.find(item => item.mindmap)?.mindmap || {};
                const corrections = (parsedData.find(item => item.corrections)?.corrections || []).filter(correction => Object.keys(correction).length > 0); // Extract corrections
                const subscoreCategoryData = parsedData.find(item => item.subscore_catetories)?.subscore_catetories || [];
                const totalScore = parsedData.find(item => item.total_score)?.total_score || 0;
                const nWordsV = parsedData.find(item => item.n_words)?.n_words || 0;
                const nSentencesV = parsedData.find(item => item.n_sentences)?.n_sentences || 0;
                const exampleAudioSrc = parsedData.find(item => item.example_audio)?.example_audio || null;
                const estimatedScoreV = grading_result.data.data?.estimated_score || 0;
                // Map through subscore categories to format the data
                const categoryDetails = subscoreCategoryData.map(category => {
                    const formattedCategory = formatCategoryName(category);
                    const categoryInfo = parsedData.find(item => item[category] !== undefined);
                    return {
                        category: formattedCategory,
                        score: categoryInfo ? categoryInfo[category] : null,
                        description: categoryInfo ? categoryInfo[`${category}_description`] : null,
                        color: getRandomColor(),
                    };
                });

                // Store corrections and count the types as array of objects
                const correctionCountsArray = [];
                corrections.forEach(correction => {
                    const existingCorrection = correctionCountsArray.find(item => item.type === correction.type);
                    if (existingCorrection) {
                        existingCorrection.count++;
                    } else {
                        const categoryName = formatCategoryName(correction.type);
                        if (categoryName !== '') {
                            correctionCountsArray.push({
                                type: formatCategoryName(correction.type),
                                count: 1,
                                color: getRandomColor()
                            });
                        }
                    }
                });

                // console.log(result)
                console.log(revisedEssay, "answer");
                console.log(keywords, "keywords")
                console.log(additonalExamples, "additonal");
                console.log(content, "content")
                console.log(result);
                console.log(subscoreData, "subscore")
                console.log(corrections, "corrections")
                console.log(correctionCounts, "count");
                console.log(totalScore, "totalScore")
                console.log(mindmap, "mindmap")
                console.log(exampleAudio, "exampleAudio")

                // Set the necessary states
                setSubscoreData(categoryDetails);
                setRevisedEssay(revisedAnswer);
                setKeywords(keywords);
                setContent(content);
                setAdditionalExamples(additionalExamples);
                setMindmap(mindmap);
                setCorrections(corrections); // Set corrections state
                setCorrectionCounts(correctionCountsArray); // Set the correction counts state as an array of objects
                setResult(parsedData);
                setTotalScore(totalScore);
                setExampleAudio(exampleAudioSrc)
                setNWords(nWordsV)
                setNSentences(nSentencesV)
                setEstimatedScore(estimatedScoreV)
                console.log(exampleAudioSrc, "exampleAudioSrc")
            } catch (error) {
                console.error('Error processing grading result:', error);
            }
        }
    }, [grading_result, revisedEssay]);


    const updatedContent = applyCorrections(content, corrections);

    const getField = (fieldName) => {
        if (grading_result.status === 'fulfilled') {
            return grading_result.data.data[fieldName];
        } else {
            return "";
        }
    }
    const getUserAudioSrc = () => {
        if (grading_result.status === 'fulfilled') {
            if ('user_audio_url' in grading_result.data.data) {
                return grading_result.data.data['user_audio_url']
            } else {
                return grading_result.data.data['user_audio'];
            }
        } else {
            return "";
        }
    }

    const getTime = () => {
        var date = new Date(grading['created_at'] * 1000);
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var date = date.getDate();
        return year + "-" + month + "-" + date;
    }

    const getType = () => {
        if (grading_result.status === 'fulfilled') {
            const gen_type = grading_result.data.data['gen_type'].split("_");
            let part1 = "";
            let part2 = "";
            if (gen_type[0] == 'speaking') {
                part1 = t("speaking");
            } else {
                part1 = t("writing");
            }

            if (gen_type[1] == "generation") {
                part2 = t("corpus_accumulation");
            } else {
                part2 = gen_type[1].replace("task", t("task"));
            }

            return part1 + part2;
        } else {
            return "";
        }
    }

    const getGenType = () => {
        if (grading_result.status === 'fulfilled') {
            return grading_result.data.data['gen_type'];
        } else {
            return "";
        }
    }

    const getBox = (fieldName) => {
        let title = fieldName;
        if (fieldName == 'content') {
            title = t("your_answer")
        } else if (fieldName == 'topic') {
            title = t('question_text')
        } else if (fieldName == 'target_score') {
            title = t('target_score')
        } else if (fieldName == 'outline') {
            title = t('outline')
        } else if (fieldName == 'listening') {
            title = t('listening_text')
        } else if (fieldName == 'reading') {
            title = t('reading_text')
        } else if (fieldName == 'conversations') {
            title = t('writing_discussion')
        }

        return (
            fieldName === 'conversations' ? (
                <ConversationTable dataType={'grading'}/>
            ) : getField(fieldName)
        );
    };


    const capitalizeFirstLetterOfEachWord = (str) => {
        return str.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());
    };

    useEffect(() => {
        console.log('Keywords:', keywords);
    }, [keywords]);

    const TreeItem = ({data, level = 0}) => {
        if (Array.isArray(data)) {
            return (
                <div>
                    {data.map((item, index) => (
                        <div key={index}>
                            <TreeItem data={item} level={level}/>
                        </div>
                    ))}
                </div>
            );
        } else if (typeof data === 'object' && data !== null) {
            return (
                <div>
                    {Object.entries(data).map(([key, value]) => {
                            if (key !== '') {
                                return <div key={key}>
                                    <div style={{marginLeft: `${(level + 1) * 6}px`}}>
                                        {`-`.repeat(level) + ' '}{key}:
                                    </div>
                                    <TreeItem data={value} level={level + 1}/>
                                </div>
                            }
                            return null;
                        }
                    )}
                </div>
            );
        } else {
            return (
                <div style={{marginLeft: `${(level + 1) * 6}px`}}>
                    {`-`.repeat(level) + ' '}{data}
                </div>
            );
        }
    };


    return (
        <div className="p-3 bg-gray-200 w-screen mt-[-10px] font-light">
            {grading_result.status === "pending" && <div>
                <div className="flex justify-center items-center">
                    <Spinner
                        thickness="4px"
                        speed="0.65s"
                        emptyColor="gray.200"
                        color="blue.500"
                        size="lg"
                    />
                </div>
            </div>}

            <div className="flex flex-row justify-between">
                <div className="flex flex-row gap-x-3 justify-center items-center">
                    <div className="font-bold text-xl">
                        {getType()}
                    </div>
                    <div className="p-2 bg-gray-400 text-center rounded-md">
                        {getTime()}
                    </div>
                    <div className="p-2 bg-yellow-100 text-center rounded-md">
                        {getGenType() === 'writing_task3' ? t('question_space') : 'TPO '}{grading.tpo}
                    </div>
                    <div className="p-2 bg-green-100 text-center rounded-md">
                        {t("Score")}：{grading.estimated_score}
                    </div>
                    {grading.is_admin_privileged && <div className="bg-sky-100 font-bold text-center rounded-md">
                        Admin Priveledged
                    </div>}
                </div>
                <div>
                    <ShareResultButton gid={gid}
                                       visible={grading.is_admin_privileged || (grading_result.status === 'fulfilled' && grading_result.data.data['is_owner'])}></ShareResultButton>
                </div>
            </div>

            <div className="mt-4 flex flex-row gap-x-5">
                {/* this one for questions, audio etc... */}
                <div className="flex flex-col w-3/4">
                    {/* question div */}

                    <div className="space-y-3 bg-white p-4 h-fit rounded-xl">
                        <div>
                            <h2 className="font-bold text-xl">Question</h2>
                            <div>{getBox('topic')}</div>
                        </div>

                        {getBox('listening') && (
                            <div>
                                <h2 className="font-bold text-xl">Listening</h2>
                                {getBox('listening')}
                            </div>
                        )}

                        {getBox('reading') && (
                            <div>
                                <h2 className="font-bold text-xl">Reading</h2>
                                {getBox('reading')}
                            </div>
                        )}

                        {(getGenType() === 'writing_task3') && (<div>
                            <h2 className="font-bold text-xl">Writing Discussion</h2>
                            {getBox('conversations')}
                        </div>)}
                        {getGenType().startsWith('speaking') && (<div>
                            <h2 className="font-bold text-xl">Your Answer</h2>
                            <div>
                                <ReactAudioPlayer
                                    src={getUserAudioSrc()}
                                    controls
                                />
                            </div>
                        </div>)
                        }
                    </div>
                    <div className="flex flex-row gap-x-3 mt-4">
                        {/* transcript */}
                        <div className="bg-white w-1/2 p-4 rounded-xl flex flex-col space-y-4">
                            <div className="flex flex-row gap-x-3 items-center">
                                <h2 className="font-bold text-xl">Transcript</h2>
                                <div className="flex flex-row gap-x-3 items-center ">
                                    <div className="rounded-full p-1 bg-gray-200 text-blue-800">{nWords}</div>
                                    <div>words</div>
                                </div>
                                <div className="flex flex-row gap-x-3 items-center ">
                                    <div className="rounded-full p-1 bg-gray-200 text-blue-800">{nSentences}</div>
                                    <div>sentences</div>
                                </div>
                            </div>
                            <div className="flex flex-row gap-x-3 overflow-y-auto custom-scrollbar">
                                {correctionCounts.map((item, index) => (
                                    <div
                                        key={index}
                                        className="rounded-full p-1 bg-gray-200 flex flex-row items-center
                                        space-x-1 border-2 border-gray-400 text-sm "
                                    >
                                        <div className={`text-${item.color}-500 font-semibold`}>{item.count}</div>
                                        <div>{item.type}</div>
                                    </div>
                                ))}
                            </div>
                            <div className=" h-96 overflow-y-auto custom-scrollbar"
                                 dangerouslySetInnerHTML={{__html: updatedContent}}
                                 onClick={handleContentClick}
                            />

                        </div>
                        {/* comments */}
                        <div className="bg-white w-1/2 p-4 rounded-xl flex flex-col space-y-4">
                            <div className="flex flex-row gap-x-3">
                                <div className="font-bold text-xl">All Comments</div>
                                <div className="rounded-full p-1 bg-gray-200 text-blue-800">{corrections.length}</div>
                            </div>
                            <div className="overflow-y-auto custom-scrollbar" style={{height: '500px'}}>
                                {corrections?.map((item, index) => (
                                    <div key={index}>
                                        <div
                                            className="bg-gray-100 rounded-xl font-bold border-l-4 border-solid border-green-600 p-3 flex items-center cursor-pointer"
                                            onClick={() => handleHighlightClick(item)}
                                        >
                                            {item?.reason}
                                        </div>
                                        {selectedCorrection === item && (
                                            <div className="bg-gray-50 p-3 mt-2 rounded-xl">
                                                <div className="mb-2"
                                                     style={{color: 'red'}}>Original: {item.original}</div>
                                                <div>Revised: {item.revised}</div>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>

                        </div>
                    </div>

                    <div className="flex flex-row gap-x-3 mt-4">
                        {/* transcript */}
                        <div className="bg-white w-1/2 p-4 rounded-xl space-y-3 flex flex-col">
                            <h2 className="font-bold text-xl">Revised Essay</h2>
                            <div className="h-96 overflow-y-auto custom-scrollbar">{revisedEssay}</div>
                        </div>
                        {/* comments */}
                        <div className="bg-white w-1/2 p-4 rounded-xl flex flex-col">

                            <div className="font-bold text-xl">Mindmap</div>
                            <div className="h-96 overflow-y-auto custom-scrollbar">
                                {Object.entries(mindmap).map(([key, value]) => (
                                    <section key={key}>
                                        <h2 className="font-bold text-lg"
                                            style={{fontFamily: 'monospace', marginTop: '1rem'}}>
                                            {capitalizeFirstLetterOfEachWord(key)}
                                        </h2>
                                        <TreeItem data={value}/>
                                    </section>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className="bg-white p-4 rounded-xl mt-4 flex flex-col">
                        <div className="font-bold text-xl">Keywords</div>
                        <div variant="striped" colorscheme="blue" size="sm"
                             className="mt-3 w-full h-96 overflow-y-auto custom-scrollbar">
                            <table variant="striped" colorscheme="blue" size="sm" className="w-full">
                                <thead>
                                <tr>
                                    <th className="border-b px-4 py-2">WORD</th>
                                    <th className="border-b px-4 py-2">PHONETIC SYMBOL</th>
                                    <th className="border-b px-4 py-2">PART OF SPEECH</th>
                                    <th className="border-b px-4 py-2">ENGLISH DEFINITION</th>
                                    <th className="border-b px-4 py-2">SAMPLE SENTENCE</th>
                                </tr>
                                </thead>
                                <tbody>
                                {keywords.map((keyword, index) => (
                                    <tr key={index} className={index % 2 === 0 ? "bg-gray-100" : "bg-white"}>
                                        <td className="border-b px-4 py-2">{keyword?.word}</td>
                                        <td className="border-b px-4 py-2">{keyword?.phonetic_symbol}</td>
                                        <td className="border-b px-4 py-2">
                                            {typeof keyword?.part_of_speech === 'object'
                                                ? Object.keys(keyword?.part_of_speech)[0]
                                                : keyword?.part_of_speech}
                                        </td>
                                        <td className="border-b px-4 py-2">{keyword?.definition}</td>
                                        <td className="border-b px-4 py-2">
                                            {typeof keyword?.sample_sentence === 'object'
                                                ? JSON.stringify(keyword?.sample_sentence)
                                                : keyword?.sample_sentence}
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="bg-white p-4 rounded-xl mt-4 mb-4 flex flex-col">
                        <div className="font-bold text-xl">Additional Examples</div>
                        <table className="mt-3 w-full">
                            <tbody className="space-y-3 h-96 overflow-y-auto custom-scrollbar">
                            {additonalExamples.map((example, index) => (
                                <tr key={index} className="rounded-xl p-2 w-fit h-fit bg-gray-100">
                                    <td>{`${index + 1}. ${example}`}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                        {(exampleAudio) && (
                            <div className="mt-4">
                                <h2 className="font-bold text-xl mb-4">Example Audio</h2>
                                <div>
                                    <ReactAudioPlayer
                                        src={exampleAudio}
                                        controls
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="w-1/4 bg-white rounded-xl flex flex-col p-3 space-y-10 mb-4">
                    <div>
                        <div className="w-100 p-6">
                            <div className="flex flex-col justify-center items-center">
                                <h1 className="text-blue-900 text-8xl font-bold">
                                    {estimatedScore}
                                </h1>
                                <div className="mt-3">Overall Score</div>
                            </div>
                        </div>

                    </div>
                    {
                        subscoreData.map((item, index) => {
                            const maxScore = getGenType().startsWith('speaking') ? 4 : 5;
                            return (
                                <CardObj
                                    key={index}
                                    color={item.color}
                                    desc={item.description}
                                    score={item.score}
                                    heading={item.category}
                                    total_score={maxScore}
                                />
                            );
                        })
                    }
                </div>
            </div>
            {/* this one for scores */}
            <div className="mt-4 text-center space-y-3 font-bold text-xl">
                <div>Feedback</div>
                <ResponseFeedBack gid={gid}></ResponseFeedBack>
            </div>

        </div>


    );
}